import {
  CenterSlot,
  Container,
  HeaderIcon,
  LeftSlot,
  NavBadge,
  NavLink,
  RightSlot,
} from './Header.styled';
import { LS_TENANT_KEY, NAV_BAR_CONFIGS } from '@/constants';
import { matchPath, useLocation } from 'react-router-dom';
import { LoggedInUserButton } from '../User';
import { IconButton, Typography } from '@mui/material';
import { DotBadge } from '../DataDisplay';
import { useSelector } from '@/hooks';
import { selectLoggedInUser } from '@/redux';
import { getDataTestId, readLocal } from '@/utils';
import { Can } from '../Can';
import { BoundCanProps } from '@casl/react';
import { AppAbility } from '@/utils/defineAbility';
import { Fragment, useCallback, useState } from 'react';
import { TenantSelect } from './TenantSelect';

export function Header() {
  const location = useLocation();
  const user = useSelector(selectLoggedInUser);
  const [savedTenant] = useState(() => readLocal(LS_TENANT_KEY));

  const renderNavItem = useCallback(
    (to: string, text: string, isComingSoon: boolean) => {
      if (isComingSoon) {
        return (
          <Typography
            {...getDataTestId(`nav-item-${!to ? 'home' : to}`)}
            variant="body1Medium"
            color={theme => theme.palette.grey[500]}
          >
            {text}
            <NavBadge label="Coming soon" ml={1} />
          </Typography>
        );
      }

      const currantPage =
        matchPath(
          {
            path: '/:tenant/:page/*',
            end: true,
          },
          location.pathname,
        )?.params.page || '';

      const absolutePath = `/${savedTenant}/${to}`;

      return (
        <NavLink
          to={absolutePath}
          {...getDataTestId(`nav-item-${!to ? 'home' : to}`)}
          isHighlighted={currantPage === to}
        >
          {text}
        </NavLink>
      );
    },
    [location.pathname, savedTenant],
  );

  if (!user) {
    return null;
  }

  return (
    <Container>
      <LeftSlot>
        <HeaderIcon name="logo" size={44} />
        <TenantSelect user={user} />
      </LeftSlot>
      <CenterSlot>
        {NAV_BAR_CONFIGS.map(({ to, text, isComingSoon, permission }) =>
          permission ? (
            <Can key={to} {...(permission as BoundCanProps<AppAbility>)}>
              {renderNavItem(to, text, isComingSoon)}
            </Can>
          ) : (
            <Fragment key={to}>
              {renderNavItem(to, text, isComingSoon)}
            </Fragment>
          ),
        )}
      </CenterSlot>
      <RightSlot>
        <IconButton>
          <HeaderIcon name="search" />
        </IconButton>
        <IconButton>
          <HeaderIcon name="question-circle" />
        </IconButton>
        <IconButton>
          <DotBadge darkBorder>
            <HeaderIcon name="bell" />
          </DotBadge>
        </IconButton>
        <LoggedInUserButton user={user} />
      </RightSlot>
    </Container>
  );
}
