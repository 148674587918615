import { getAPIErrorMessage, getDataTestId } from '@/utils';
import { Can } from '../../Can';
import { DrawerProps } from '../Modals.interface';
import { Button, Grid, Typography } from '@mui/material';
import { LoadingButton, Select, Switch } from '../../Form';
import { Drawer, Field, FieldsContainer } from './ExportClientDrawer.styled';
import { CLIENT_STATUSES, DATE_VALUE_FORMAT } from '@/constants';
import { useDispatch, useForm, useModal, useSelector } from '@/hooks';
import { DatePicker } from '../../Form/DatePicker';
import { selectConfigsState, showToast } from '@/redux';
import { DrawerFooter, Form } from '../Modals.styled';
import { ClientStatus, ExportClientsForm, ModalName } from '@/types';
import { useMutation } from '@tanstack/react-query';
import { downloadExportedClients } from '@/api';
import { useEffect, useMemo } from 'react';
import { format, isBefore, set, sub } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Status } from '../../DataDisplay';

type ExportClientDrawerProps = DrawerProps;

const EXPORT_FIELD_TEXT: Record<
  keyof Omit<ExportClientsForm, 'dateFrom' | 'dateTo'>,
  string
> = {
  applicationStatuses: 'Application status',
  name: 'Name',
  dob: 'Date of Birth',
  gender: 'Gender',
  email: 'Email',
  phone: 'Phone',
  address: 'Address',
  rim: 'RIM number',
  attestationStatus: 'Attestation status',
  comments: 'Notes',
};

export function ExportClientDrawer({ ...props }: ExportClientDrawerProps) {
  const { dateFormat } = useSelector(selectConfigsState);
  const { hide } = useModal({
    name: ModalName.ExportClient,
    open: !!props.open,
    onClosed: () => {
      reset();
    },
  });
  const dispatch = useDispatch();

  const { defaultFromDate, defaultToDate } = useMemo(() => {
    const now = new Date();
    const fromDate = sub(now, { months: 1 });

    return {
      defaultFromDate: format(fromDate, DATE_VALUE_FORMAT),
      defaultToDate: format(now, DATE_VALUE_FORMAT),
    };
  }, []);
  const {
    control,
    visibleErrors,
    formState: { isValid },
    handleSubmit,
    reset,
    watch,
    trigger,
  } = useForm<ExportClientsForm>({
    defaultValues: {
      dateFrom: defaultFromDate,
      dateTo: defaultToDate,
      applicationStatuses: [ClientStatus.Rejected],
      name: false,
      dob: false,
      gender: false,
      email: false,
      phone: false,
      address: false,
      rim: false,
      attestationStatus: false,
      comments: false,
    },
  });

  const dateFrom = watch('dateFrom');
  const dateTo = watch('dateTo');

  const { minFromDate, maxFromDate } = useMemo(() => {
    trigger('dateFrom');
    const today = new Date();
    const minFromDate = sub(today, { months: 3 });
    const maxFromDate = today;

    return {
      minFromDate,
      maxFromDate,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateTo]);

  const minToDate = useMemo(() => {
    trigger('dateTo');
    const minToDate = new Date(dateFrom);
    return minToDate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom]);

  const { mutate, isPending, isSuccess, error } = useMutation({
    mutationFn: downloadExportedClients,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showToast({
          message: 'Clients exported successful.',
          severity: 'success',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      dispatch(
        showToast({
          message: getAPIErrorMessage(error),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onSubmit = handleSubmit(payload => {
    const { dateFrom, dateTo, ...rest } = payload;

    if (!dateFrom || !dateTo) return;

    const dateToEOD = set(dateTo, { hours: 23, minutes: 59, seconds: 59 });
    const dateFromSOD = set(dateFrom, { hours: 0, minutes: 0, seconds: 0 });

    mutate({
      ...rest,
      dateFrom: formatInTimeZone(dateFromSOD, 'UTC', "yyyy-MM-dd'T'HH:mm:ssX"),
      dateTo: formatInTimeZone(dateToEOD, 'UTC', "yyyy-MM-dd'T'HH:mm:ssX"),
    });
  });

  const validateDates = (
    fromValue?: string,
    toValue?: string,
    target: 'from' | 'to' = 'from',
  ) => {
    if (!toValue || !fromValue) {
      return true;
    }

    const from = new Date(fromValue as string);
    const to = new Date(toValue);
    const today = new Date();

    const isFutureDay =
      target === 'from' ? isBefore(today, from) : isBefore(today, to);

    if (isFutureDay) {
      return 'Cannot be day in future';
    }

    if (isBefore(to, from)) {
      return target === 'to'
        ? 'Cannot be before From date'
        : 'Cannot be after To date';
    }
  };

  return (
    <Can do="export" on="client">
      <Drawer
        {...getDataTestId('export-client-drawer')}
        {...props}
        title="Export clients"
        onClose={hide}
      >
        <Form component="form" onSubmit={onSubmit}>
          <Typography variant="body1Bold">Date</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DatePicker
                label="From"
                name="dateFrom"
                format={dateFormat}
                control={control}
                error={!!visibleErrors.dateFrom}
                helperText={visibleErrors.dateFrom?.message}
                rules={{
                  required: 'Field required!',
                  validate: {
                    isBeforeDateTo: (value, formValues) => {
                      const toValue = formValues['dateTo'];
                      return validateDates(value as string, toValue);
                    },
                  },
                }}
                disabled={isPending}
                disableInput={true}
                minDate={minFromDate}
                maxDate={maxFromDate}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="To"
                name="dateTo"
                format={dateFormat}
                control={control}
                error={!!visibleErrors.dateTo}
                helperText={
                  visibleErrors.dateTo?.message !==
                  visibleErrors.dateFrom?.message
                    ? visibleErrors.dateTo?.message
                    : undefined
                }
                rules={{
                  required: 'Field required!',
                  validate: {
                    isAfterDateFrom: (value, formValues) => {
                      const fromValue = formValues['dateFrom'];
                      return validateDates(fromValue, value as string, 'to');
                    },
                  },
                }}
                disabled={isPending}
                disableFuture
                minDate={minToDate}
              />
            </Grid>
          </Grid>
          <Typography variant="body1Bold" mt={1}>
            Select information to export
          </Typography>
          <FieldsContainer>
            {Object.entries(EXPORT_FIELD_TEXT).map(([key, text]) =>
              key === 'applicationStatuses' ? (
                <Field key={`export-field-${key}`}>
                  <Typography variant="body2">{text}</Typography>
                  <Select
                    placeholder="Select statuses"
                    options={CLIENT_STATUSES}
                    control={control}
                    name="applicationStatuses"
                    multiline
                    multiple
                    displayEmpty
                    disabled={isPending}
                    renderMultipleSelectedOption={status => (
                      <Status key={status} value={status} />
                    )}
                  />
                </Field>
              ) : (
                <Field key={`export-field-${key}`}>
                  <Typography variant="body2">{text}</Typography>
                  <Switch
                    control={control}
                    name={key as keyof ExportClientsForm}
                    disabled={isPending}
                  />
                </Field>
              ),
            )}
          </FieldsContainer>
          <DrawerFooter align="stretch">
            <Button
              variant="outlined"
              onClick={hide}
              disabled={isPending}
              {...getDataTestId(`export-clients-cancel-button`)}
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={!isValid}
              type="submit"
              isLoading={isPending}
              {...getDataTestId(`export-clients-export-button`)}
            >
              Export
            </LoadingButton>
          </DrawerFooter>
        </Form>
      </Drawer>
    </Can>
  );
}
