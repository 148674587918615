import {
  ApproveAccountDrawer,
  AssignClientModal,
  DocumentReviewDrawer,
  FileViewer,
  Header,
  PEPReportDrawer,
  Toast,
  AddUserDrawer,
  AbilityContext,
  ManageDocumentsDrawer,
  RequestDocumentDrawer,
  ExportClientDrawer,
} from '@/components';
import { useDispatch, useSelector } from '@/hooks';
import {
  fetchSigningForms,
  fetchReviewers,
  selectUIState,
  hideModal,
  fetchRiskLevels,
  fetchUserRoles,
  fetchPermissions,
  selectLoggedInUser,
  fetchFeatures,
  fetchBranches,
} from '@/redux';
import { Client, ModalName, PermissionName, Tenant, User } from '@/types';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AuthRootContainer } from './Root.styled';
import { useEffect } from 'react';
import { buildAbilityFor } from '@/utils/defineAbility';

export function AuthenticatedPage() {
  const {
    manageDocumentsDrawer,
    documentRequestDrawer,
    documentReviewDrawer,
    addUserDrawer,
    approveAccountDrawer,
    assignClientModal,
    exportClientDrawer,
    pepReportDrawer,
    fileViewer,
    toast,
  } = useSelector(selectUIState);
  const dispatch = useDispatch();
  const user = useSelector(selectLoggedInUser);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchFeatures());
    dispatch(fetchReviewers());
    dispatch(fetchBranches());
    dispatch(fetchRiskLevels());
    dispatch(fetchSigningForms());
    if (user?.permissions?.includes(PermissionName.RoleView)) {
      dispatch(fetchUserRoles());
      dispatch(fetchPermissions());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const urlTenant = location.pathname.split('/').filter(i => !!i)[0];
    if (!(Object.values(Tenant) as string[]).includes(urlTenant)) {
      navigate('/', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (manageDocumentsDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.ManageDocuments }));
    }
    if (documentRequestDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.RequestDocument }));
    }
    if (documentReviewDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.ReviewDocument }));
    }
    if (addUserDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.AddUser }));
    }
    if (pepReportDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.PEPReport }));
    }
    if (approveAccountDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.ApproveAccount }));
    }
    if (exportClientDrawer.isOpen) {
      dispatch(hideModal({ modalName: ModalName.ExportClient }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <AbilityContext.Provider value={buildAbilityFor(user as User)}>
      <AuthRootContainer>
        <Header />
        <Outlet />
        <ManageDocumentsDrawer
          open={manageDocumentsDrawer.isOpen}
          payload={manageDocumentsDrawer.payload}
        />
        <RequestDocumentDrawer
          open={documentRequestDrawer.isOpen}
          payload={documentRequestDrawer.payload}
        />
        <DocumentReviewDrawer
          open={documentReviewDrawer.isOpen}
          client={documentReviewDrawer.payload?.client}
          document={documentReviewDrawer.payload?.document}
        />
        <AddUserDrawer
          open={addUserDrawer.isOpen}
          user={addUserDrawer.payload?.user}
          isEdit={addUserDrawer.payload?.isEdit}
          isChangeRole={addUserDrawer.payload?.isChangeRole}
        />
        {fileViewer.document && <FileViewer document={fileViewer.document} />}
        <AssignClientModal
          open={assignClientModal.isOpen}
          client={assignClientModal.payload}
        />
        <ExportClientDrawer open={exportClientDrawer.isOpen} />
        <ApproveAccountDrawer
          open={approveAccountDrawer.isOpen}
          client={approveAccountDrawer.payload}
        />
        <PEPReportDrawer
          open={pepReportDrawer.isOpen}
          client={pepReportDrawer.payload as Client}
        />
        <Toast
          open={toast.isOpen}
          message={toast.message}
          severity={toast.severity}
          autoHide={toast.autoHide}
        />
      </AuthRootContainer>
    </AbilityContext.Provider>
  );
}
