import { useSelector } from '@/hooks';
import { selectLoggedInUser } from '@/redux';
import { getDefaultTenant } from '@/utils';
import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function TenantNavigator() {
  const navigate = useNavigate();
  const user = useSelector(selectLoggedInUser);

  useLayoutEffect(() => {
    const tenant = getDefaultTenant(user);
    navigate(`/${tenant}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
