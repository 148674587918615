import { ClientStatus } from '@/types';

export const ClientDBFields: Record<string, string> = {
  firstName: 'first_name',
  lastName: 'last_name',
  status: 'status',
  assignee: 'review_by',
};

export const ExportClientDBFields: Record<string, string> = {
  attestationStatus: 'attestation_status',
};

export const ClientAddressFields = [
  'line1',
  'line2',
  'city',
  'state',
  'town',
  'district',
  'zip',
];

export const ADDRESS_MAX_LENGTH = {
  line1: 40,
  city: 40,
  state: 40,
  zip: 10,
};

export const EMPLOYER_MAX_LENGTH = {
  employerName: 40,
  employerAddress: 40,
  employerPhone: 40,
};

export const CLIENT_STATUS_STEP: Record<string, number> = {
  [ClientStatus.Draft]: 1,
  [ClientStatus.InReview]: 2,
  [ClientStatus.PendingApproval]: 3,
  [ClientStatus.Approved]: 4,
  [ClientStatus.Archived]: 5,
  [ClientStatus.Rejected]: 6,
};
