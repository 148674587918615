import { Option } from '@/types';

export function isNil(value: unknown) {
  return value === undefined || value === null;
}

export function getFileExtension(file: File) {
  return '.' + file.name.toLowerCase().split('.').pop();
}

export function getSortOptionsFn(order: 'asc' | 'desc' = 'asc') {
  return (a: Option, b: Option) => {
    const aText = String(a.text || a.value);
    const bText = String(b.text || b.value);
    return order === 'asc'
      ? aText.localeCompare(bText)
      : bText.localeCompare(aText);
  };
}
