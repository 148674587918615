import { Navigate, RouteObject, useRoutes } from 'react-router-dom';
import { HomePage } from '../home';
import { AbilityRouteHandler } from './AbilityRouteHandler';
import { ClientDetailsPage, ClientsPage } from '../clients';
import { AuthenticatedPage } from './AuthenticatedApp';
import { TenantNavigator } from './TenantNavigator';
import { ROUTE } from '@/constants';
import { DocumentsPage } from '../documents';
import { ActivityLogsPage } from '../activity-logs';
import { ChangePasswordPage } from '../change-password';
import { AdministrationPage } from '../administration';
import { RoleDetail } from '../administration/RoleDetail';

const authorizedRoutes: RouteObject[] = [
  {
    path: '/',
    element: <TenantNavigator />,
  },
  {
    path: ':tenant',
    element: <AuthenticatedPage />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: ROUTE.CLIENTS.index,
        children: [
          {
            index: true,
            element: (
              <AbilityRouteHandler
                permission={{
                  do: 'view',
                  on: 'client',
                }}
              >
                <ClientsPage />
              </AbilityRouteHandler>
            ),
          },
          {
            path: ROUTE.CLIENTS.CLIENT_DETAILS,
            element: (
              <AbilityRouteHandler
                permission={{
                  do: 'view',
                  on: 'client',
                }}
              >
                <ClientDetailsPage />
              </AbilityRouteHandler>
            ),
          },
        ],
      },
      {
        path: ROUTE.DOCUMENTS,
        element: (
          <AbilityRouteHandler
            permission={{
              do: 'view',
              on: 'document',
            }}
          >
            <DocumentsPage />
          </AbilityRouteHandler>
        ),
      },
      {
        path: ROUTE.ACTIVITY_LOGS,
        element: (
          <AbilityRouteHandler
            permission={{
              do: 'view',
              on: 'report',
            }}
          >
            <ActivityLogsPage />
          </AbilityRouteHandler>
        ),
      },
      {
        path: ROUTE.CHANGE_PASSWORD,
        element: <ChangePasswordPage />,
      },
      {
        path: ROUTE.ADMINISTRATION.index,
        children: [
          {
            index: true,
            element: (
              <AbilityRouteHandler
                permission={{
                  do: 'view',
                  on: 'administration',
                }}
              >
                <AdministrationPage />
              </AbilityRouteHandler>
            ),
          },
          {
            path: ROUTE.ADMINISTRATION.ROLE_DETAILS,
            element: (
              <AbilityRouteHandler
                permission={{
                  do: 'view',
                  on: 'administration',
                }}
              >
                <RoleDetail />
              </AbilityRouteHandler>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  },
];

export function AuthenticatedRoutes() {
  const authRoutes = useRoutes(authorizedRoutes);

  return authRoutes;
}
