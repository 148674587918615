import { Client } from './client';

export enum DocumentSection {
  ProofOfAddress = 'Proof_of_Address',
  ProofOfEmployment = 'Proof_of_Employment',
  TIN = 'TIN_Number',
  DocumentSign = 'Document_Sign',
  Identity = 'Identity',
  SecondID = 'Second_ID',
  Selfie = 'SN',
  IdentityBack = 'Identity_Back',
  Internal = 'Internal_Document',
  Generated = 'Generated_Document',
  BranchApproval = 'Branch_Approval',
  ProofOfIncome = 'Proof_of_Income',
}

export enum DocumentType {
  UtilityBill = 'Utility_Bill',
  UtilityBillNonOwner = 'Utility_Bill_Non_Owner',
  BankStatement = 'Bank_Statement',
  GemeenteUitreksel = 'Gemeente_Uitreksel',
  CertificateOfTitle = 'Certificate_Of_Title',
  FireInsurance = 'Fire_Insurance',
  RatesAndTaxesReceiptInvoice = 'Rates_And_Taxes_Receipt_Invoice',
  TinCertificate = 'Tin_Certificate',
  LetterFromToshaosHinterlandLocations = 'Letter_From_Toshaos_Hinterland_Locations',
  PostMarkedEnvelopeWithCustomerNameAndAddress = 'Post_Marked_Envelope_With_Customer_Name_And_Address',
  LeaseAgreement = 'Lease_Agreement',
  LetterOfAuthorisationFromUtilityBillOwner = 'Letter_Of_Authorisation_From_Utility_Bill_Owner',
  RegistrationForm = 'Registration_Form',
  CBBExtract = 'CBB_Extract',
  CopyOfUtilityBillOwnerID = 'Copy_Of_Utility_Bill_Owner_ID',
  SelfAttestationForProofOfAddress = 'Self_Attestation_For_Proof_Of_Address',
  BankReferenceLetter = 'Bank_Reference_Letter',
  ConfirmationOfAddress = 'Confirmation_Of_Address',
  DriverLicense = 'Driver_License',
  LetterOfEmployment = 'Letter_Of_Employment',
  RecentPayslip = 'Recent_Payslip',
  LatestFiledTaxReturn = 'Latest_Filed_Tax_Return',
  FinancialStatements = 'Financial_Statements',
  FinancialStatementsProjections = 'Financial_Statements_Projections',
  IncomeStatement = 'Income_Statement',
  SchoolLetterOfAttendance = 'School_Letter_Of_Attendance',
  SupportLetterFromParent = 'Support_Letter_From_Parent',
  ParentID = 'Parent_ID',
  AuthorisationLetter = 'Authorisation_Letter',
  SocialSecurityLetter = 'Social_Security_Letter',
  SupportLetterFromHusband = 'Support_Letter_From_Husband',
  SupportLetterFromCaretaker = 'Support_Letter_From_Caretaker',
  ProfessionalRecommendationLetter = 'Professional_Recommendation_Letter',
  TradeLicense = 'Trade_License',
  ProofOfPropertyOwnershipNotarialDeed = 'Proof_Of_Property_Ownership_Notarial_Deed',
  ForecastProjections = 'Forecast_Projections',
  ProjectedCashFlows = 'Projected_Cash_Flows',
  IncomeAndExpenditureStatement = 'Income_And_Expenditure_Statement',
  SelfAttestationForProofOfIncome = 'Self_Attestation_For_Proof_Of_Income',
  ExtractFromChamberOfCommerce = 'Extract_From_Chamber_Of_Commerce',
  Selfie = 'SN',
  Id = 'ID',
  IdentityDriverLicense = 'D',
  SecurityCard = 'Security_card',
  Passport = 'Passport',
  Nis = 'NIS',
  IDC = 'IDC_Form',
  AML5 = 'AML5_Customer_Risk_Rating_Form',
  OneCardOrder = 'One_Card_Order_Form',
  None = 'None',
  Unknown = 'Unknown',
}

export type RequiredDocument = {
  name: DocumentType;
  status?: DocumentStatus;
  displayName: string;
};

export enum RequiredCondition {
  Or = 'or',
  And = 'and',
}

export type RequiredDocumentGroup = {
  condition: RequiredCondition;
  items: RequiredDocument[];
  status?: DocumentStatus;
};

export type RequiredDocumentSection = {
  type: DocumentSection;
  docs: string[];
  status: DocumentStatus;
  groups?: RequiredDocumentGroup[];
};

export enum DocumentStatus {
  AboutToExpire = 'ABOUT_TO_EXPIRE',
  Requested = 'REQUESTED',
  Pending = 'PENDING',
  InReview = 'IN_REVIEW',
  Cancelled = 'CANCELLED',
  Rejected = 'REJECTED',
  Expired = 'EXPIRED',
  Approved = 'APPROVED',
  Signing = 'SIGNING',
  Empty = 'EMPTY',
  Generated = 'GENERATED',
}

export enum SourceType {
  Web = 'web',
  Image = 'img',
  PDF = 'pdf',
}

export type Document = {
  id: number | string;
  fileName?: string;
  operationId?: number | string;
  status: DocumentStatus;
  type: DocumentSection;
  docType?: DocumentType;
  vuOperationGuid?: number | string;
  vuOperationId?: number | string;
  adobeSignWidgetId?: string;
  createdAt?: string;
  docNumber?: string;
  expiryDate?: string;
  contentType?: string;
  uri?: string;
  reviewDueDate?: string;
  overdueText?: string;
  vuResponseCode?: number;
  clientName: string;
  clientId: string;
  updatedAt?: string;
  reviewMessage?: string;
  owner?: Client;
  message?: string;
  formName?: string;
};

export type SigningDocument = {
  id: string;
  name: string;
  url: string;
  javascript: string;
  sender: string;
  status?: DocumentStatus;
  formName?: string;
};

export type DocumentSectionQuestion = {
  question: string;
  description?: string;
  answer: boolean;
};

export enum SigningFormType {
  Authorization,
  W9,
  FATCA_1A,
  CA43A,
  AML6,
  AML4,
}
