import { doNotForwardTheseProps } from '@/styles';
import { Accordion, AccordionDetails, Box, styled } from '@mui/material';

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
  '.MuiAccordionSummary-root': {
    gap: theme.spacing(1),
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.background.paper,
    '.MuiAccordionSummary-root': {
      opacity: 1,
      '.MuiAccordionSummary-expandIconWrapper': {
        display: 'none',
      },
    },
  },
}));

export const DocumentAccordionDetails = styled(AccordionDetails)(() => ({
  position: 'relative',
}));

export const DocumentAccordionItemContainer = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('noBorder'),
})<{ noBorder?: boolean }>(({ theme, noBorder }) => ({
  padding: `${theme.spacing(1.75)} 0`,
  ':first-of-type': {
    paddingTop: 0,
  },
  ':last-of-type': {
    paddingBottom: 0,
  },
  ':not(:first-of-type)': {
    borderTop: noBorder ? 'none' : `1px solid ${theme.palette.grey[300]}`,
  },
}));

export const DocumentAccordionFooter = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(-2),
  width: `calc(100% + ${theme.spacing(4)})`,
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  display: 'flex',
}));
