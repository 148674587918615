import { Theme } from '@mui/material';

export type SelectVariant = 'primary' | 'grey';

export type IconName =
  | 'trash-simple'
  | 'minus-circle'
  | 'delete'
  | 'lock'
  | 'unlock'
  | 'user-gear'
  | 'password'
  | 'switch-role'
  | 'add'
  | 'alert'
  | 'alert-outline'
  | 'arrow-arc-left'
  | 'arrow-arc-right'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-left-alt'
  | 'arrow-right'
  | 'arrow-up'
  | 'bell'
  | 'block'
  | 'calendar-blank'
  | 'caret-down'
  | 'caret-circle-left'
  | 'caret-circle-right'
  | 'chat-dots'
  | 'chat-empty'
  | 'chat-lines'
  | 'check'
  | 'check-circle-outline'
  | 'check-circle'
  | 'check-fat'
  | 'check-fat-outline'
  | 'check-mini'
  | 'check-square-offset'
  | 'clock'
  | 'close'
  | 'download'
  | 'edit-outline'
  | 'entra-id'
  | 'eye-close'
  | 'eye-open'
  | 'file-dash'
  | 'file-download'
  | 'file-excel'
  | 'file-image'
  | 'file-pdf'
  | 'file-plus'
  | 'file-x'
  | 'files'
  | 'filter'
  | 'filter-outline'
  | 'fire'
  | 'gear-fine'
  | 'globe'
  | 'hammer'
  | 'id-card'
  | 'info-outline'
  | 'info'
  | 'logo'
  | 'more'
  | 'note'
  | 'paper-plane'
  | 'question-circle'
  | 'reject'
  | 'reset'
  | 'reset-clockwise'
  | 'search'
  | 'search-doc'
  | 'search-text'
  | 'sign-in'
  | 'sign-out'
  | 'siren'
  | 'spinner'
  | 'spinner-alt'
  | 'sort-asc'
  | 'sort-desc'
  | 'upload'
  | 'upload-alt'
  | 'warning'
  | 'zoom-in'
  | 'zoom-out'
  | 'pending-dots'
  // Flags
  | 'flag-abkhazia'
  | 'flag-afghanistan'
  | 'flag-aland-islands'
  | 'flag-albania'
  | 'flag-algeria'
  | 'flag-american-samoa'
  | 'flag-andorra'
  | 'flag-angola'
  | 'flag-anguilla'
  | 'flag-antigua-and-barbuda'
  | 'flag-argentina'
  | 'flag-armenia'
  | 'flag-aruba'
  | 'flag-australia'
  | 'flag-austria'
  | 'flag-azerbaijan'
  | 'flag-azores-islands'
  | 'flag-bahamas'
  | 'flag-bahrain'
  | 'flag-balearic-islands'
  | 'flag-bangladesh'
  | 'flag-barbados'
  | 'flag-basque-country'
  | 'flag-belarus'
  | 'flag-belgium'
  | 'flag-belize'
  | 'flag-benin'
  | 'flag-bermuda'
  | 'flag-bhutan'
  | 'flag-bolivia'
  | 'flag-bonaire'
  | 'flag-bosnia-and-herzegovina'
  | 'flag-botswana'
  | 'flag-brazil'
  | 'flag-british-columbia'
  | 'flag-british-indian-ocean-territory'
  | 'flag-british-virgin-islands'
  | 'flag-brunei'
  | 'flag-bulgaria'
  | 'flag-burkina-faso'
  | 'flag-burundi'
  | 'flag-cambodia'
  | 'flag-cameroon'
  | 'flag-canada'
  | 'flag-canary-islands'
  | 'flag-cape-verde'
  | 'flag-cayman-islands'
  | 'flag-central-african-republic'
  | 'flag-ceuta'
  | 'flag-chad'
  | 'flag-chile'
  | 'flag-china'
  | 'flag-cocos-island'
  | 'flag-columbia'
  | 'flag-comoros'
  | 'flag-cook-islands'
  | 'flag-corsica'
  | 'flag-costa-rica'
  | 'flag-croatia'
  | 'flag-cuba'
  | 'flag-curacao'
  | 'flag-cyprus'
  | 'flag-czech-republic'
  | 'flag-democratic-republic-of-congo'
  | 'flag-denmark'
  | 'flag-djibouti'
  | 'flag-dominica'
  | 'flag-dominican-republic'
  | 'flag-east-timor'
  | 'flag-ecuador'
  | 'flag-egypt'
  | 'flag-el-salvador'
  | 'flag-england'
  | 'flag-equatorial-guinea'
  | 'flag-eritrea'
  | 'flag-estonia'
  | 'flag-ethiopia'
  | 'flag-falkland-islands'
  | 'flag-faroe-islands'
  | 'flag-fiji'
  | 'flag-finland'
  | 'flag-france'
  | 'flag-french-polynesia'
  | 'flag-gabon'
  | 'flag-galapagos-islands'
  | 'flag-gambia'
  | 'flag-georgia'
  | 'flag-germany'
  | 'flag-ghana'
  | 'flag-gibraltar'
  | 'flag-greece'
  | 'flag-greenland'
  | 'flag-grenada'
  | 'flag-guam'
  | 'flag-guatemala'
  | 'flag-guernsey'
  | 'flag-guinea-bissau'
  | 'flag-guinea'
  | 'flag-guyana'
  | 'flag-haiti'
  | 'flag-hawaii'
  | 'flag-honduras'
  | 'flag-hong-kong'
  | 'flag-hungary'
  | 'flag-iceland'
  | 'flag-india'
  | 'flag-indonesia'
  | 'flag-iran'
  | 'flag-iraq'
  | 'flag-ireland'
  | 'flag-isle-of-man'
  | 'flag-israel'
  | 'flag-italy'
  | 'flag-ivory-coast'
  | 'flag-jamaica'
  | 'flag-japan'
  | 'flag-jersey'
  | 'flag-jordan'
  | 'flag-kazakhstan'
  | 'flag-kenya'
  | 'flag-kiribati'
  | 'flag-kosovo'
  | 'flag-kuwait'
  | 'flag-kyrgyzstan'
  | 'flag-laos'
  | 'flag-latvia'
  | 'flag-lebanon'
  | 'flag-lesotho'
  | 'flag-liberia'
  | 'flag-libya'
  | 'flag-liechtenstein'
  | 'flag-lithuania'
  | 'flag-luxembourg'
  | 'flag-macao'
  | 'flag-madagascar'
  | 'flag-madeira'
  | 'flag-malawi'
  | 'flag-malaysia'
  | 'flag-maldives'
  | 'flag-mali'
  | 'flag-malta'
  | 'flag-marshall-island'
  | 'flag-martinique'
  | 'flag-mauritania'
  | 'flag-mauritius'
  | 'flag-melilla'
  | 'flag-mexico'
  | 'flag-micronesia'
  | 'flag-moldova'
  | 'flag-monaco'
  | 'flag-mongolia'
  | 'flag-montenegro'
  | 'flag-montserrat'
  | 'flag-morocco'
  | 'flag-mozambique'
  | 'flag-myanmar'
  | 'flag-namibia'
  | 'flag-nauru'
  | 'flag-nepal'
  | 'flag-netherlands'
  | 'flag-new-zealand'
  | 'flag-nicaragua'
  | 'flag-niger'
  | 'flag-nigeria'
  | 'flag-niue'
  | 'flag-norfolk-island'
  | 'flag-north-korea'
  | 'flag-northern-cyprus'
  | 'flag-northern-marianas-islands'
  | 'flag-norway'
  | 'flag-oman'
  | 'flag-orkney-islands'
  | 'flag-ossetia'
  | 'flag-pakistan'
  | 'flag-palau'
  | 'flag-palestine'
  | 'flag-panama'
  | 'flag-papua-new-guinea'
  | 'flag-paraguay'
  | 'flag-peru'
  | 'flag-philippines'
  | 'flag-pitcairn-islands'
  | 'flag-poland'
  | 'flag-portugal'
  | 'flag-puerto-rico'
  | 'flag-qatar'
  | 'flag-rapa-nui'
  | 'flag-republic-of-macedonia'
  | 'flag-republic-of-the-congo'
  | 'flag-romania'
  | 'flag-rwanda'
  | 'flag-saba-island'
  | 'flag-sahrawi-arab-democratic-republic'
  | 'flag-samoa'
  | 'flag-san-marino'
  | 'flag-sao-tome-and-prince'
  | 'flag-sardinia'
  | 'flag-saudi-arabia'
  | 'flag-scotland'
  | 'flag-senegal'
  | 'flag-serbia'
  | 'flag-seychelles'
  | 'flag-sierra-leone'
  | 'flag-singapore'
  | 'flag-sint-eustatius'
  | 'flag-sint-maarten'
  | 'flag-slovakia'
  | 'flag-slovenia'
  | 'flag-solomon-islands'
  | 'flag-somalia'
  | 'flag-somaliland'
  | 'flag-south-africa'
  | 'flag-south-korea'
  | 'flag-south-sudan'
  | 'flag-spain'
  | 'flag-sri-lanka'
  | 'flag-st-barts'
  | 'flag-st-kitts-nevis'
  | 'flag-st-lucia'
  | 'flag-st-maarten'
  | 'flag-st-vincent-and-the-grenadines'
  | 'flag-sudan'
  | 'flag-suriname'
  | 'flag-swaziland'
  | 'flag-sweden'
  | 'flag-switzerland'
  | 'flag-syria'
  | 'flag-taiwan'
  | 'flag-tajikistan'
  | 'flag-tanzania'
  | 'flag-thailand'
  | 'flag-tibet'
  | 'flag-togo'
  | 'flag-tokelau'
  | 'flag-tonga'
  | 'flag-transnistria'
  | 'flag-trinidad-and-tobago'
  | 'flag-tunisia'
  | 'flag-turkey'
  | 'flag-turkmenistan'
  | 'flag-turks-and-caicos'
  | 'flag-tuvalu'
  | 'flag-uganda'
  | 'flag-ukraine'
  | 'flag-united-arab-emirates'
  | 'flag-united-kingdom'
  | 'flag-united-states'
  | 'flag-uruguay'
  | 'flag-uzbekistan'
  | 'flag-vanuatu'
  | 'flag-vatican-city'
  | 'flag-venezuela'
  | 'flag-vietnam'
  | 'flag-virgin-islands'
  | 'flag-wales'
  | 'flag-yemen'
  | 'flag-zambia'
  | 'flag-zimbabwe'
  | 'flag-ec';

export enum OptionValue {
  All = 'all',
  Yes = 'option-value-yes',
  No = 'option-value-no',
  NotYet = 'option-value-not-yet',
}

export type Option = {
  value: string | number;
  text?: string;
  icon?: IconName;
  group?: string;
  groupOrder?: number;
  disabled?: boolean;
};

export type DotVariant = 'dark-border' | 'light-border';

export type ColorExtractFn = (theme: Theme) => string | undefined;

export type StatusProps = {
  value: string;
  label?: string;
  color?: ColorExtractFn;
  bgColor?: ColorExtractFn;
  borderColor?: ColorExtractFn;
  size?: 'small' | 'medium';
  display?: 'inline' | 'block';
  title?: string;
};

export type FileFrameActionItem = {
  icon: IconName;
  title: string;
  onClick: () => void;
};
