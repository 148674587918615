import { Grid, Typography } from '@mui/material';
import { DocumentAccordionAnsweredQuestionProps } from './DocumentAccordion.interface';

export function DocumentAccordionAnsweredQuestion({
  question,
}: DocumentAccordionAnsweredQuestionProps) {
  return (
    <Grid container spacing={2} alignItems="center" marginBottom={2}>
      <Grid item xs={8}>
        <Typography variant="body2Bold">{question.question}</Typography>
        <Typography variant="body2">{question.description}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="body2Medium">
          Answer:{' '}
          <Typography
            variant="body2Medium"
            component="span"
            color={theme =>
              !question.answer
                ? theme.palette.green[500]
                : theme.palette.red[500]
            }
          >
            {question.answer ? 'Yes' : 'No'}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
}
