import { ClientStatus } from './client';
import { DocumentStatus } from './document';

export type Pagination = {
  page: number;
  size: number;
  total: number;
};

export type ApiFilter = {
  pagination?: Pagination;
  query?: string;
  sort?: string;
  filter?: Record<string, string[]>;
  dueFrom?: string;
  dueTo?: string;
  from?: string;
  to?: string;
  excludeFields?: string[];
  fields?: string[];
  dateFrom?: string;
  dateTo?: string;
};

export type DataList<T> = {
  data: T[];
} & Pagination;

export enum GenericStatus {
  Verified = 'verified',
  Unverified = 'unverified',
}

export type CommonStatus = DocumentStatus | ClientStatus;

export enum MessageTemplateType {
  RequestDocument = 'request_document',
}

export enum SignInStep {
  Login = 'LOG_IN',
  ForgotPassword = 'FORGOT_PASSWORD',
  AmplifyNewPasswordRequired = 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED',
}

export enum ResetPasswordStep {
  AmplifyDone = 'DONE',
  AmplifyConfirmCode = 'CONFIRM_RESET_PASSWORD_WITH_CODE',
}

export enum Environment {
  Development = 'DEVELOPMENT',
  Staging = 'STAGING',
  Production = 'PRODUCTION',
}

export enum Tenant {
  Anguilla = 'aia',
  Barbados = 'brb',
  BVI = 'bvi',
  Cayman = 'cym',
  Dominica = 'dma',
  Ghana = 'gha',
  Grenada = 'grd',
  Guyana = 'guy',
  SintMaarten = 'sxm',
  StKitts = 'kna',
  StLucia = 'lca',
  StVincent = 'vct',
  Suriname = 'sur',
  Trinidad = 'tto',
}

export type InputValueHelperRule = {
  text: string;
  isMatched: (value?: string | number) => boolean;
};
