import {
  AuditActorType,
  AuditLogStatus,
  CautionCheckStatus,
  ClientStatus,
  ClientTitle,
  DocumentStatus,
  DocumentType,
  Gender,
  JobStatus,
  JobType,
  Option,
  OptionValue,
  RoleName,
  SummaryPeriod,
  UserStatus,
} from '@/types';
import {
  DOCUMENT_TYPE_TEXT,
  ClientStatusText,
  StatusText,
  GenderText,
  RoleNameText,
  UserStatusText,
  ScheduleJobTypeText,
  OptionValueText,
  ClientTitleText,
} from './mapper';

export const USER_STATUSES: Option[] = [
  {
    value: UserStatus.Active,
    text: UserStatusText[UserStatus.Active],
  },
  {
    value: UserStatus.Locked,
    text: UserStatusText[UserStatus.Locked],
  },
];

export const CLIENT_STATUSES: Option[] = [
  {
    value: ClientStatus.Draft,
    text: ClientStatusText[ClientStatus.Draft],
  },
  {
    value: ClientStatus.PendingApproval,
    text: ClientStatusText[ClientStatus.PendingApproval],
  },
  {
    value: ClientStatus.Approved,
    text: ClientStatusText[ClientStatus.Approved],
  },
  {
    value: ClientStatus.Rejected,
    text: ClientStatusText[ClientStatus.Rejected],
  },
  {
    value: ClientStatus.Archived,
    text: ClientStatusText[ClientStatus.Archived],
  },
];

export const CLIENT_STATUSES_WITH_ALL: Option[] = [
  {
    value: OptionValue.All,
    text: 'All Statuses',
  },
  ...CLIENT_STATUSES,
];

export const DOCUMENT_TYPES: Option[] = [
  {
    value: DocumentType.UtilityBill,
    text: DOCUMENT_TYPE_TEXT[DocumentType.UtilityBill],
  },
  {
    value: DocumentType.UtilityBillNonOwner,
    text: DOCUMENT_TYPE_TEXT[DocumentType.UtilityBillNonOwner],
  },
  {
    value: DocumentType.BankStatement,
    text: DOCUMENT_TYPE_TEXT[DocumentType.BankStatement],
  },
  {
    value: DocumentType.GemeenteUitreksel,
    text: DOCUMENT_TYPE_TEXT[DocumentType.GemeenteUitreksel],
  },
  {
    value: DocumentType.CertificateOfTitle,
    text: DOCUMENT_TYPE_TEXT[DocumentType.CertificateOfTitle],
  },
  {
    value: DocumentType.FireInsurance,
    text: DOCUMENT_TYPE_TEXT[DocumentType.FireInsurance],
  },
  {
    value: DocumentType.RatesAndTaxesReceiptInvoice,
    text: DOCUMENT_TYPE_TEXT[DocumentType.RatesAndTaxesReceiptInvoice],
  },
  {
    value: DocumentType.TinCertificate,
    text: DOCUMENT_TYPE_TEXT[DocumentType.TinCertificate],
  },
  {
    value: DocumentType.LetterFromToshaosHinterlandLocations,
    text: DOCUMENT_TYPE_TEXT[DocumentType.LetterFromToshaosHinterlandLocations],
  },
  {
    value: DocumentType.PostMarkedEnvelopeWithCustomerNameAndAddress,
    text: DOCUMENT_TYPE_TEXT[
      DocumentType.PostMarkedEnvelopeWithCustomerNameAndAddress
    ],
  },
  {
    value: DocumentType.LeaseAgreement,
    text: DOCUMENT_TYPE_TEXT[DocumentType.LeaseAgreement],
  },
  {
    value: DocumentType.LetterOfAuthorisationFromUtilityBillOwner,
    text: DOCUMENT_TYPE_TEXT[
      DocumentType.LetterOfAuthorisationFromUtilityBillOwner
    ],
  },
  {
    value: DocumentType.RegistrationForm,
    text: DOCUMENT_TYPE_TEXT[DocumentType.RegistrationForm],
  },
  {
    value: DocumentType.CBBExtract,
    text: DOCUMENT_TYPE_TEXT[DocumentType.CBBExtract],
  },
  {
    value: DocumentType.CopyOfUtilityBillOwnerID,
    text: DOCUMENT_TYPE_TEXT[DocumentType.CopyOfUtilityBillOwnerID],
  },
  {
    value: DocumentType.SelfAttestationForProofOfAddress,
    text: DOCUMENT_TYPE_TEXT[DocumentType.SelfAttestationForProofOfAddress],
  },
  {
    value: DocumentType.BankReferenceLetter,
    text: DOCUMENT_TYPE_TEXT[DocumentType.BankReferenceLetter],
  },
  {
    value: DocumentType.ConfirmationOfAddress,
    text: DOCUMENT_TYPE_TEXT[DocumentType.ConfirmationOfAddress],
  },
  {
    value: DocumentType.LetterOfEmployment,
    text: DOCUMENT_TYPE_TEXT[DocumentType.LetterOfEmployment],
  },
  {
    value: DocumentType.RecentPayslip,
    text: DOCUMENT_TYPE_TEXT[DocumentType.RecentPayslip],
  },
  {
    value: DocumentType.LatestFiledTaxReturn,
    text: DOCUMENT_TYPE_TEXT[DocumentType.LatestFiledTaxReturn],
  },
  {
    value: DocumentType.FinancialStatements,
    text: DOCUMENT_TYPE_TEXT[DocumentType.FinancialStatements],
  },
  {
    value: DocumentType.FinancialStatementsProjections,
    text: DOCUMENT_TYPE_TEXT[DocumentType.FinancialStatementsProjections],
  },
  {
    value: DocumentType.IncomeStatement,
    text: DOCUMENT_TYPE_TEXT[DocumentType.IncomeStatement],
  },
  {
    value: DocumentType.SchoolLetterOfAttendance,
    text: DOCUMENT_TYPE_TEXT[DocumentType.SchoolLetterOfAttendance],
  },
  {
    value: DocumentType.SupportLetterFromParent,
    text: DOCUMENT_TYPE_TEXT[DocumentType.SupportLetterFromParent],
  },
  {
    value: DocumentType.ParentID,
    text: DOCUMENT_TYPE_TEXT[DocumentType.ParentID],
  },
  {
    value: DocumentType.AuthorisationLetter,
    text: DOCUMENT_TYPE_TEXT[DocumentType.AuthorisationLetter],
  },
  {
    value: DocumentType.SocialSecurityLetter,
    text: DOCUMENT_TYPE_TEXT[DocumentType.SocialSecurityLetter],
  },
  {
    value: DocumentType.SupportLetterFromHusband,
    text: DOCUMENT_TYPE_TEXT[DocumentType.SupportLetterFromHusband],
  },
  {
    value: DocumentType.SupportLetterFromCaretaker,
    text: DOCUMENT_TYPE_TEXT[DocumentType.SupportLetterFromCaretaker],
  },
  {
    value: DocumentType.ProfessionalRecommendationLetter,
    text: DOCUMENT_TYPE_TEXT[DocumentType.ProfessionalRecommendationLetter],
  },
  {
    value: DocumentType.TradeLicense,
    text: DOCUMENT_TYPE_TEXT[DocumentType.TradeLicense],
  },
  {
    value: DocumentType.ProofOfPropertyOwnershipNotarialDeed,
    text: DOCUMENT_TYPE_TEXT[DocumentType.ProofOfPropertyOwnershipNotarialDeed],
  },
  {
    value: DocumentType.ForecastProjections,
    text: DOCUMENT_TYPE_TEXT[DocumentType.ForecastProjections],
  },
  {
    value: DocumentType.ProjectedCashFlows,
    text: DOCUMENT_TYPE_TEXT[DocumentType.ProjectedCashFlows],
  },
  {
    value: DocumentType.IncomeAndExpenditureStatement,
    text: DOCUMENT_TYPE_TEXT[DocumentType.IncomeAndExpenditureStatement],
  },
  {
    value: DocumentType.SelfAttestationForProofOfIncome,
    text: DOCUMENT_TYPE_TEXT[DocumentType.SelfAttestationForProofOfIncome],
  },
  {
    value: DocumentType.ExtractFromChamberOfCommerce,
    text: DOCUMENT_TYPE_TEXT[DocumentType.ExtractFromChamberOfCommerce],
  },
  { value: DocumentType.Selfie, text: DOCUMENT_TYPE_TEXT[DocumentType.Selfie] },
  { value: DocumentType.Id, text: DOCUMENT_TYPE_TEXT[DocumentType.Id] },
  {
    value: DocumentType.IdentityDriverLicense,
    text: DOCUMENT_TYPE_TEXT[DocumentType.IdentityDriverLicense],
  },
  {
    value: DocumentType.SecurityCard,
    text: DOCUMENT_TYPE_TEXT[DocumentType.SecurityCard],
  },
  {
    value: DocumentType.Passport,
    text: DOCUMENT_TYPE_TEXT[DocumentType.Passport],
  },
  { value: DocumentType.Nis, text: DOCUMENT_TYPE_TEXT[DocumentType.Nis] },
].sort(getSortOptionsFn());

export const ID_TYPES: Option[] = [
  {
    value: DocumentType.Id,
    text: DOCUMENT_TYPE_TEXT[DocumentType.Id],
  },
  {
    value: DocumentType.Passport,
    text: DOCUMENT_TYPE_TEXT[DocumentType.Passport],
  },
  {
    value: DocumentType.IdentityDriverLicense,
    text: DOCUMENT_TYPE_TEXT[DocumentType.IdentityDriverLicense],
  },
  {
    value: DocumentType.Nis,
    text: DOCUMENT_TYPE_TEXT[DocumentType.Nis],
  },
];

export const DOCUMENT_STATUSES: Option[] = [
  {
    value: DocumentStatus.InReview,
    text: StatusText[DocumentStatus.InReview],
  },
  {
    value: DocumentStatus.Approved,
    text: StatusText[DocumentStatus.Approved],
  },
  {
    value: DocumentStatus.AboutToExpire,
    text: StatusText[DocumentStatus.AboutToExpire],
  },
  {
    value: DocumentStatus.Expired,
    text: StatusText[DocumentStatus.Expired],
  },
  {
    value: DocumentStatus.Requested,
    text: StatusText[DocumentStatus.Requested],
  },
  {
    value: DocumentStatus.Rejected,
    text: StatusText[DocumentStatus.Rejected],
  },
  {
    value: DocumentStatus.Generated,
    text: StatusText[DocumentStatus.Generated],
  },
];

export const DOCUMENT_STATUSES_WITH_ALL: Option[] = [
  {
    value: OptionValue.All,
    text: 'All Statuses',
  },
  ...DOCUMENT_STATUSES,
];

export const TIME_PERIODS: Option[] = [
  {
    value: SummaryPeriod.Today,
    text: 'Today',
  },
  {
    value: SummaryPeriod.Month,
    text: 'This month',
  },
  {
    value: SummaryPeriod.Year,
    text: 'This year',
  },
];

export const TIME_PERIOD_TARGETS = {
  [SummaryPeriod.Today]: '1',
  [SummaryPeriod.Month]: '20',
  [SummaryPeriod.Year]: '240',
};

export const DOCUMENT_UNKNOWN_FILTER_OPTIONS: Option[] = [
  {
    value: OptionValue.All,
    text: 'All',
  },
  {
    value: 'onboarding',
    text: 'Onboarding',
  },
  // Temporarily hidden - GDO-1100
  // {
  //   value: 'credit-cards',
  //   text: 'Credit Cards',
  // },
];

export const AUDIT_ACTOR_TYPES: Option[] = [
  {
    value: AuditActorType.BackOffice,
    text: 'BackOffice',
  },
  {
    value: AuditActorType.Onboarding,
    text: 'Onboarding client',
  },
];

export const AUDIT_ACTOR_TYPES_WITH_SCHEDULE_JOBS: Option[] = [
  ...AUDIT_ACTOR_TYPES,
  {
    value: AuditActorType.ScheduleJob,
    text: 'Schedule jobs',
  },
];

export const GENDERS: Option[] = [
  {
    value: Gender.Female,
    text: GenderText[Gender.Female],
  },
  {
    value: Gender.Male,
    text: GenderText[Gender.Male],
  },
];

export const USER_ROLE_TYPES: Option[] = [
  {
    value: RoleName.Admin,
    text: RoleNameText[RoleName.Admin],
  },
  {
    value: RoleName.Corporate,
    text: RoleNameText[RoleName.Corporate],
  },
  {
    value: RoleName.Approver,
    text: RoleNameText[RoleName.Approver],
  },
  {
    value: RoleName.ManualReviewer,
    text: RoleNameText[RoleName.ManualReviewer],
  },
  {
    value: RoleName.ProductionSupport,
    text: RoleNameText[RoleName.ProductionSupport],
  },
  {
    value: RoleName.ViewOnly,
    text: RoleNameText[RoleName.ViewOnly],
  },
].sort((a, b) => a.text.localeCompare(b.text));

export const AUDIT_LOG_STATUSES: Option[] = [
  {
    value: AuditLogStatus.Success,
    text: 'Success',
  },
  {
    value: AuditLogStatus.Failure,
    text: 'Failure',
  },
];

export const SCHEDULE_JOB_STATUSES: Option[] = [
  {
    value: JobStatus.Pending,
    text: 'Pending',
  },
  {
    value: JobStatus.Processing,
    text: 'Processing',
  },
  {
    value: JobStatus.Success,
    text: 'Success',
  },
  {
    value: JobStatus.Error,
    text: 'Error',
  },
];

export const SCHEDULE_JOB_TYPES: Option[] = [
  {
    value: JobType.CreateBankAccount,
    text: ScheduleJobTypeText[JobType.CreateBankAccount],
  },
  {
    value: JobType.CreateICBProfile,
    text: ScheduleJobTypeText[JobType.CreateICBProfile],
  },
  {
    value: JobType.CreateRIM,
    text: ScheduleJobTypeText[JobType.CreateRIM],
  },
  {
    value: JobType.UpdateRIM,
    text: ScheduleJobTypeText[JobType.UpdateRIM],
  },
  {
    value: JobType.FundAccount,
    text: ScheduleJobTypeText[JobType.FundAccount],
  },
];

export const YES_NO_OPTIONS: Option[] = [
  {
    value: OptionValue.Yes,
    text: OptionValueText[OptionValue.Yes],
  },
  {
    value: OptionValue.No,
    text: OptionValueText[OptionValue.No],
  },
];

export const CLIENT_TITLE_OPTIONS: Option[] = [
  {
    value: ClientTitle.MR,
    text: ClientTitleText[ClientTitle.MR],
  },
  {
    value: ClientTitle.MS,
    text: ClientTitleText[ClientTitle.MS],
  },
  {
    value: ClientTitle.MRS,
    text: ClientTitleText[ClientTitle.MRS],
  },
];

export const CAUTION_STATUS_OPTIONS: Option[] = [
  {
    value: OptionValue.Yes,
    text: StatusText[CautionCheckStatus.Matched],
  },
  {
    value: OptionValue.No,
    text: StatusText[CautionCheckStatus.UnMatched],
  },
];

function getSortOptionsFn(order: 'asc' | 'desc' = 'asc') {
  return (a: Option, b: Option) => {
    const aText = String(a.text || a.value);
    const bText = String(b.text || b.value);
    return order === 'asc'
      ? aText.localeCompare(bText)
      : bText.localeCompare(aText);
  };
}
