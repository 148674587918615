import {
  Comment,
  FileCommentInput,
  FileContainer,
  Form,
} from './Modals.styled';
import { FormAction } from '../Layout';
import { DrawerProps } from './Modals.interface';
import { Alert, Box, Button, Typography } from '@mui/material';
import {
  Client,
  Document,
  DocumentStatus,
  ModalName,
  ReviewDocumentForm,
} from '@/types';
import { Icon } from '../Icon';
import {
  getAPIErrorMessage,
  getDataTestId,
  getDisplayField,
  getTimeDuration,
  isExcelContentType,
  isInactiveClient,
  isInactiveDocument,
} from '@/utils';
import {
  ChangeEventHandler,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { DOCUMENT_TYPE_TEXT } from '@/constants';
import { FileFrame } from '../File';
import { useDispatch, useModal } from '@/hooks';
import { showToast } from '@/redux';
import { Status } from '../DataDisplay';
import { useMutation } from '@tanstack/react-query';
import { reviewDocument } from '@/api';
import { LoadingButton } from '../Form';
import { Can } from '../Can';
import { BasicDrawer } from './BasicDrawer';
import { DocumentDrawer } from './DocumentDrawer.styled';

type DocumentReviewDrawerProps = {
  client?: Client;
  document?: Document;
} & DrawerProps;

export function DocumentReviewDrawer({
  client,
  document: documentData,
  ...props
}: DocumentReviewDrawerProps) {
  const [payload, setPayload] = useState<Partial<ReviewDocumentForm>>({});
  const dispatch = useDispatch();
  const { hide, hideAndRefresh } = useModal({ name: ModalName.ReviewDocument });
  const { mutate, isSuccess, isPending, error, reset } = useMutation({
    mutationFn: reviewDocument,
  });
  const isActiveDocument =
    client &&
    !isInactiveClient(client) &&
    documentData &&
    !isInactiveDocument(documentData);

  useEffect(() => {
    if (props.open) {
      setPayload({
        clientId: client?.id,
        documentId: documentData?.id,
        message: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open, documentData]);

  const onSetPayload = (value: Partial<ReviewDocumentForm>) => {
    setPayload(prev => ({
      ...prev,
      ...value,
    }));
  };

  useEffect(() => {
    if (payload.status) {
      reset();
      mutate(payload as ReviewDocumentForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  useEffect(() => {
    if (isSuccess && payload.status) {
      if (payload.status === DocumentStatus.Approved) {
        dispatch(
          showToast({
            message: 'Document approved successfully!',
            severity: 'success',
          }),
        );
      } else if (payload.status === DocumentStatus.Rejected) {
        dispatch(
          showToast({
            message: 'Document were rejected. Request new.',
            severity: 'error',
          }),
        );
      }
      onSetPayload({
        status: undefined,
      });
      hideAndRefresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  const onMessageChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
    onSetPayload({
      message: event.target.value,
    });
  };

  const onApprove = () => {
    onSetPayload({
      status: DocumentStatus.Approved,
    });
  };

  const onReject = () => {
    onSetPayload({
      status: DocumentStatus.Rejected,
    });
  };

  if (!document) {
    return (
      <BasicDrawer
        {...getDataTestId('document-review-drawer')}
        {...props}
        title="Document review"
        onClose={hide}
      />
    );
  }

  const renderMessage = () => {
    if (documentData?.status === DocumentStatus.AboutToExpire) {
      return (
        <Typography variant="body2" textAlign="center">
          The document is about to expire in{' '}
          <Bold>
            {getTimeDuration(new Date().getTime(), documentData?.expiryDate)}
          </Bold>
          ! Please request the most recent version of the{' '}
          {DOCUMENT_TYPE_TEXT[documentData?.type]} from{' '}
          <Bold>
            {documentData?.owner
              ? getDisplayField(documentData?.owner)
              : 'the client'}
          </Bold>
          .
        </Typography>
      );
    } else if (!isActiveDocument) {
      return null;
    }

    return (
      <Typography variant="body2" textAlign="center">
        Please review this{' '}
        {documentData?.docType ? (
          <Bold>
            {DOCUMENT_TYPE_TEXT[documentData?.docType as string] ||
              documentData?.docType}
          </Bold>
        ) : (
          'document'
        )}{' '}
        and decide whether to approve or reject it based on its content,
        accuracy, and compliance
      </Typography>
    );
  };

  const renderAction = () => {
    if (!isActiveDocument) {
      return (
        <FormAction align="stretch">
          <Button
            variant="outlined"
            onClick={hide}
            {...getDataTestId(`review-document-action-cancel-button`)}
          >
            Cancel
          </Button>
        </FormAction>
      );
    }

    return (
      <FormAction align="stretch">
        <Can do="review" on="document">
          <LoadingButton
            variant="outlined"
            startIcon={<Icon name="file-x" />}
            onClick={onReject}
            disabled={isPending}
            isLoading={isPending && payload.status === DocumentStatus.Rejected}
            {...getDataTestId(`review-document-action-reject-button`)}
          >
            Reject
          </LoadingButton>
          <LoadingButton
            startIcon={<Icon name="check" />}
            onClick={onApprove}
            disabled={isPending}
            isLoading={isPending && payload.status === DocumentStatus.Approved}
            {...getDataTestId(`review-document-action-approve-button`)}
          >
            Approve
          </LoadingButton>
        </Can>
      </FormAction>
    );
  };

  return (
    <DocumentDrawer
      {...getDataTestId('document-review-drawer')}
      {...props}
      onClose={hide}
      title={
        <>
          <span>
            {DOCUMENT_TYPE_TEXT[String(documentData?.docType)] ||
              documentData?.docType ||
              DOCUMENT_TYPE_TEXT[String(documentData?.type)] ||
              'Document review'}
          </span>
          {!isActiveDocument && (
            <>
              {' '}
              <Status value={String(documentData?.status)} display="inline" />
            </>
          )}
        </>
      }
    >
      <Form>
        {renderMessage()}
        <FileContainer>
          {documentData && (
            <FileFrame
              document={documentData}
              disabledCache={isExcelContentType(documentData.contentType)}
            />
          )}
        </FileContainer>
        {isActiveDocument ? (
          <Can do="review" on="document">
            <FileCommentInput
              placeholder="Write your message"
              onChange={onMessageChange}
              value={payload.message}
              disabled={isPending}
            />
          </Can>
        ) : documentData?.reviewMessage ? (
          <Comment>{documentData?.reviewMessage}</Comment>
        ) : null}
        {error && (
          <Box mb={2}>
            <Alert
              icon={<Icon name="alert" />}
              color="error"
              variant="standard"
            >
              {getAPIErrorMessage(error)}
            </Alert>
          </Box>
        )}
        {renderAction()}
      </Form>
    </DocumentDrawer>
  );
}

const Bold = ({ children }: PropsWithChildren) => (
  <Typography variant="body2Bold" component="span">
    {children}
  </Typography>
);
