import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  getSignFormType,
  isUSCitizen,
  isUSTaxResidence,
  standardizeDocumentsToSign,
} from '@/utils';
import { AccountTypeDetails, Client, SigningFormType } from '@/types';

export const selectDocumentsState = createSelector(
  (state: RootState) => state.documents,
  data => ({ ...data }),
);

export const selectSigningDocuments = ({
  client,
  clientAccountTypes,
}: {
  client?: Client;
  clientAccountTypes?: AccountTypeDetails[];
} = {}) =>
  createSelector(
    (state: RootState) => state.documents,
    data =>
      standardizeDocumentsToSign(
        data.signingForms,
        clientAccountTypes || [],
      ).filter(item => {
        const formType = getSignFormType(item);
        if (formType === SigningFormType.W9) {
          return (
            isUSCitizen(client) || isUSTaxResidence(client) || client?.usIndicia
          );
        }
        return true;
      }),
  );

export const selectLocalDocuments = createSelector(
  (state: RootState) => state.documents,
  data => data.documentResource,
);

export const selectLocalDocument = (uri?: string) =>
  createSelector(
    (state: RootState) => state.documents.documentResource,
    data => (uri ? data[uri] : null),
  );
