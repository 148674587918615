import { DocumentSection, DocumentStatus } from '@/types';

export const DocumentDBFields: Record<string, string> = {
  updatedAt: 'updated_at',
};

export const ABOUT_TO_EXPIRE_DURATION_MILISECONDS = 1000 * 60 * 60 * 24 * 30;

export const DOCUMENT_STATUS_STEP_OR: Record<string, number> = {
  [DocumentStatus.Approved]: 1,
  [DocumentStatus.InReview]: 2,
  [DocumentStatus.Signing]: 3,
  [DocumentStatus.Expired]: 4,
  [DocumentStatus.Rejected]: 5,
  [DocumentStatus.Pending]: 6,
};

export const DOCUMENT_STATUS_STEP_AND: Record<string, number> = {
  [DocumentStatus.Rejected]: 1,
  [DocumentStatus.Pending]: 2,
  [DocumentStatus.Signing]: 3,
  [DocumentStatus.InReview]: 4,
  [DocumentStatus.Approved]: 5,
  [DocumentStatus.Expired]: 6,
};

export const SUPPORTED_INTERNAL_DOCUMENT_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.pdf',
];
export const SUPPORTED_MANUAL_UPLOAD_DOCUMENT_EXTENSIONS = [
  '.png',
  '.jpg',
  '.jpeg',
  '.pdf',
];
export const MAX_DOCUMENT_SIZE = 52428800;

export const DOCUMENT_SECTION_ORDER: Record<DocumentSection, number> = {
  [DocumentSection.Identity]: 1,
  [DocumentSection.IdentityBack]: 2,
  [DocumentSection.Selfie]: 3,
  [DocumentSection.TIN]: 4,
  [DocumentSection.SecondID]: 5,
  [DocumentSection.ProofOfAddress]: 6,
  [DocumentSection.ProofOfEmployment]: 7,
  [DocumentSection.ProofOfIncome]: 8,
  [DocumentSection.DocumentSign]: 9,
  [DocumentSection.BranchApproval]: 10,
  [DocumentSection.Internal]: 11,
  [DocumentSection.Generated]: 12,
};
