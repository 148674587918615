import { ColorExtractFn, MyTasksStats, SummaryStats } from '@/types';
import { Theme } from '@mui/material';

export const TOAST_DURATION = 3000;

export const SUMMARY_FIGURE_TYPES: {
  field: keyof SummaryStats;
  text: string;
  color: ColorExtractFn;
  value: number;
  showWarning?: boolean;
}[] = [
  {
    field: 'pendingCustomer',
    text: 'Pending customer',
    color: (theme: Theme) => theme.palette.grey[200],
    value: 0,
  },
  {
    field: 'pendingApproval',
    text: 'Pending approval',
    color: (theme: Theme) => theme.palette.secondaryPink[200],
    value: 0,
  },
  {
    field: 'openedAccounts',
    text: 'Opened accounts',
    color: (theme: Theme) => theme.palette.green[200],
    value: 0,
  },
  {
    field: 'rejectedAccounts',
    text: 'Rejected accounts',
    color: (theme: Theme) => theme.palette.red[200],
    value: 0,
    showWarning: true,
  },
];

export const MY_TASKS_FIGURE_TYPES: {
  field: keyof MyTasksStats;
  text: string;
  color: ColorExtractFn;
  value: number;
  showWarning?: boolean;
}[] = [
  {
    field: 'pendingApproval',
    value: 0,
    text: 'Pending approval',
    color: (theme: Theme) => theme.palette.grey[200],
  },
  {
    field: 'total',
    value: 0,
    text: 'Assigned to me',
    color: (theme: Theme) => theme.palette.primaries[200],
  },
  {
    field: 'dueToday',
    value: 0,
    text: 'Due today',
    color: (theme: Theme) => theme.palette.green[200],
  },
];
