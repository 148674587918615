import { doNotForwardTheseProps } from '@/styles';
import {
  styled,
  Drawer as MUIDrawer,
  Box,
  BoxProps,
  Typography,
} from '@mui/material';
import { FullHeightTextField } from '../Form';
import { FormAction, FormHeader, OverlayScroller } from '../Layout';

export const StyledBasicDrawer = styled(MUIDrawer, {
  shouldForwardProp: doNotForwardTheseProps('width'),
})<{ width?: number }>(({ width = 552 }) => ({
  '.MuiDrawer-paper': {
    borderTopLeftRadius: 32,
    borderBottomLeftRadius: 32,
    width,

    '.FormAction': {
      '.MuiButton-root': {
        height: 44,
      },
    },
  },
}));

export const BasicDrawerContent = styled(OverlayScroller)(({ theme }) => ({
  padding: theme.spacing(3),
  paddingTop: 0,
  position: 'relative',
}));

export const BasicDrawerHeader = styled(FormHeader)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  position: 'sticky',
  top: 0,
  zIndex: 2,
  marginBottom: theme.spacing(1),
}));
BasicDrawerHeader.defaultProps = {
  withDivider: true,
};

export const DrawerFooter = styled(FormAction)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.grey[300]}`,
  marginLeft: theme.spacing(-3),
  marginBottom: theme.spacing(-3),
  padding: theme.spacing(3),
  width: `calc(100% + ${theme.spacing(6)})`,
}));

export const Form = styled(Box)<BoxProps>(({ theme }) => ({
  minHeight: 0,
  flex: 1,
  flexDirection: 'column',
  display: 'flex',
  gap: theme.spacing(2.5),
}));
Form.defaultProps = {
  component: 'form',
};

export const FileContainer = styled(Box)(() => ({
  flex: 1,
  minHeight: 300,
}));

export const FileCommentInput = styled(FullHeightTextField)(() => ({
  flex: 'initial',
  height: 100,
}));

export const Comment = styled(Typography)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(2),
}));
Comment.defaultProps = {
  variant: 'body2',
};

export const ApproveAccountComment = styled(FullHeightTextField)(() => ({
  minHeight: 100,
}));

export const AssignClientModalForm = styled(Form)(({ theme }) => ({
  width: 312,
  gap: theme.spacing(4),
}));

export const ApproveAccountBrief = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  borderRadius: 10,
  backgroundColor: theme.palette.grey[100],
  padding: `${theme.spacing(3)} ${theme.spacing(3)} ${theme.spacing(4)}`,
  marginTop: `${theme.spacing(3)} 0`,
}));
