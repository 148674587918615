import {
  AbilityContext,
  Figure,
  Heading,
  Icon,
  ProgressBar,
  ToggleGroup,
} from '@/components';
import { Alert, Grid, Typography } from '@mui/material';
import { MouseEvent, useContext, useMemo, useState } from 'react';
import { DashboardBlock } from './Home.styled';
import {
  TWO_DAY_SECONDS,
  ROUTE,
  SUMMARY_FIGURE_TYPES,
  TIME_PERIOD_TARGETS,
  TIME_PERIODS,
} from '@/constants';
import { useQuery } from '@tanstack/react-query';
import { getSummary } from '@/api';
import { ClientStatus, SummaryPeriod } from '@/types';
import { getAPIErrorMessage, getNavigateObject } from '@/utils';
import { formatInTimeZone } from 'date-fns-tz';
import { set } from 'date-fns';
import { useNavigate } from 'react-router-dom';

export function SectionSummary() {
  const ability = useContext(AbilityContext);
  const [period, setPeriod] = useState<SummaryPeriod>(SummaryPeriod.Today);
  const navigate = useNavigate();

  const { data, error } = useQuery({
    queryKey: ['summary-stats', period],
    queryFn: () => getSummary(period),
    retry: false,
  });

  const figures = useMemo(() => {
    return SUMMARY_FIGURE_TYPES.map(config => {
      let onClick: (() => void) | undefined;

      const now = new Date();
      let startTime = set(now, { hours: 0, minutes: 0, seconds: 0 });
      const endTime = set(now, { hours: 23, minutes: 59, seconds: 59 });

      if (period === SummaryPeriod.Month) {
        startTime = set(now, { date: 1, hours: 0, minutes: 0, seconds: 0 });
      } else if (period === SummaryPeriod.Year) {
        startTime = set(now, {
          month: 0,
          date: 1,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
      }

      const timeRange = {
        from: formatInTimeZone(startTime, 'UTC', "yyyy-MM-dd'T'HH:mm:ssX"),
        to: formatInTimeZone(endTime, 'UTC', "yyyy-MM-dd'T'HH:mm:ssX"),
      };

      switch (config.field) {
        case 'pendingCustomer':
          onClick = () =>
            navigate(
              getNavigateObject(ROUTE.CLIENTS.index, {
                queries: {
                  applicationStatuses: ClientStatus.Draft,
                  ...timeRange,
                },
              }),
            );
          break;
        case 'pendingApproval':
          onClick = () =>
            navigate(
              getNavigateObject(ROUTE.CLIENTS.index, {
                queries: {
                  applicationStatuses: ClientStatus.PendingApproval,
                  ...timeRange,
                },
              }),
            );
          break;
        case 'openedAccounts':
          onClick = () =>
            navigate(
              getNavigateObject(ROUTE.CLIENTS.index, {
                queries: {
                  applicationStatuses: ClientStatus.Approved,
                  ...timeRange,
                },
              }),
            );
          break;
        case 'rejectedAccounts':
          onClick = () =>
            navigate(
              getNavigateObject(ROUTE.CLIENTS.index, {
                queries: {
                  applicationStatuses: ClientStatus.Rejected,
                  ...timeRange,
                },
              }),
            );
          break;
      }

      return {
        ...config,
        value: data?.[config.field] || 0,
        onClick,
      };
    });
  }, [data, navigate, period]);

  const onPeriodChange = (_: MouseEvent<HTMLElement>, value: SummaryPeriod) => {
    setPeriod(value);
  };

  return (
    <DashboardBlock>
      <Heading
        title="Summary"
        actionSlot={
          <ToggleGroup
            name="summary-period"
            value={period}
            onChange={onPeriodChange}
            options={TIME_PERIODS}
          />
        }
        isSubSection
      />
      {error && (
        <Alert icon={<Icon name="alert" />} color="error" variant="standard">
          {getAPIErrorMessage(error)}
        </Alert>
      )}
      <Grid container spacing={2} rowSpacing={3}>
        <Grid item xs={6}>
          <Typography variant="body2Bold" mb={1}>
            Response time
          </Typography>
          <ProgressBar
            value={TWO_DAY_SECONDS}
            total={
              !data?.responseTime
                ? 0
                : Math.pow(data.responseTime, 2) / TWO_DAY_SECONDS
            }
            currentText={() =>
              `${!data?.responseTime ? '-' : Math.round((data.responseTime / 3600) * 10) / 10}h`
            }
            totalText={() => `Target 2 business days`}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2Bold" mb={1}>
            Accounts opened
          </Typography>
          <ProgressBar
            value={data?.openedAccounts || 0}
            total={parseInt(TIME_PERIOD_TARGETS[period]) || 0}
            currentText={value => `${value}`}
            totalText={() => `Target ${TIME_PERIOD_TARGETS[period]}`}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2Bold" mb={1}>
            Accounts overview
          </Typography>
          <Grid container spacing={1}>
            {figures.map(({ showWarning, value, text, color, onClick }) => (
              <Grid key={text} item xs={3} xl={3}>
                <Figure
                  value={value}
                  title={text}
                  color={color}
                  height={108}
                  py={2}
                  px={1}
                  withWarningIcon={showWarning}
                  {...(ability.can('view', 'client') && { onClick })}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </DashboardBlock>
  );
}
