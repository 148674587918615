import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { Branch } from '@/constants';

export const selectPicklists = createSelector(
  (state: RootState) => state.picklists,
  data => ({
    ...data,
  }),
);

export const selectReviewers = createSelector(
  (state: RootState) => state.picklists,
  data => data.reviewers,
);

export const selectBranches = createSelector(
  (state: RootState) => state.picklists,
  data => ({
    branchById: data.branches.reduce(
      (acc, item) => ({
        ...acc,
        [item.id]: item,
      }),
      {} as Record<string, Branch>,
    ),
    options: data.branches.map(item => ({
      text: item.branchName,
      value: item.id,
    })),
  }),
);

export const selectRiskLevels = createSelector(
  (state: RootState) => state.picklists,
  data => ({
    riskLevels: data.riskLevels,
    riskLevelOptions: data.riskLevelOptions,
    riskLevelByCode: data.riskLevelByCode,
  }),
);

export const selectOccupations = createSelector(
  (state: RootState) => state.configs,
  data => data.occupations,
);
