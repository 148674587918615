import { useDispatch, useDownloadDocument, useSelector } from '@/hooks';
import { selectClientDetails, showDocumentReviewDrawer } from '@/redux';
import { Document, DocumentStatus } from '@/types';
import {
  formatTime,
  getDataTestId,
  isExcelContentType,
  isImageContentType,
  isPDFContentType,
  isViewSupportedDocument,
} from '@/utils';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { DocumentItem, FileThumbnail } from './ClientDetails.styled';
import { Can, Icon, Status } from '@/components';
import { DOCUMENT_TYPE_TEXT } from '@/constants';

export function TabAllDocuments() {
  const client = useSelector(selectClientDetails);
  const dispatch = useDispatch();
  const downloadDocument = useDownloadDocument();

  const viewFile = (document: Document) => {
    dispatch(showDocumentReviewDrawer({ client, document }));
  };

  const documents = (client?.documents ? [...client.documents] : []).sort(
    (a, b) => (a.fileName || '').localeCompare('' + b.fileName),
  );

  return (
    <Grid container spacing={3} alignItems="stretch">
      {documents.map(item => {
        const shouldShowDue = ![
          DocumentStatus.Expired,
          DocumentStatus.Approved,
          DocumentStatus.Rejected,
        ].includes(item.status);

        return (
          <Grid
            {...getDataTestId(`document-card-${item.id}`)}
            key={item.id}
            item
            xs={4}
          >
            <DocumentItem>
              <FileThumbnail
                isExpired={item.status === DocumentStatus.Expired}
                isAboutToExpired={item.status === DocumentStatus.AboutToExpire}
              >
                {
                  <Icon
                    size={48}
                    name={
                      isPDFContentType(item.contentType)
                        ? 'file-pdf'
                        : isImageContentType(item.contentType)
                          ? 'file-image'
                          : isExcelContentType(item.contentType)
                            ? 'file-excel'
                            : 'file-dash'
                    }
                    color={theme =>
                      item.status === DocumentStatus.Expired
                        ? theme.palette.red[600]
                        : item.status === DocumentStatus.AboutToExpire
                          ? theme.palette.orange[600]
                          : theme.palette.grey[700]
                    }
                  />
                }
                <Can do="view" on="document">
                  <Stack gap={3}>
                    {isViewSupportedDocument(item) && (
                      <IconButton onClick={() => viewFile(item)}>
                        <Icon name="search-doc" />
                      </IconButton>
                    )}
                    <IconButton onClick={() => downloadDocument(item)}>
                      <Icon name="download" />
                    </IconButton>
                  </Stack>
                </Can>
              </FileThumbnail>
              <Typography variant="body2" mt={2} mb={1} className="FileName">
                {item.fileName ||
                  DOCUMENT_TYPE_TEXT['' + item.docType] ||
                  'Document'}
              </Typography>
              <Typography
                variant="caption"
                color={theme => theme.palette.grey[700]}
              >
                {DOCUMENT_TYPE_TEXT[item.type]}
              </Typography>
              <Stack justifyContent="space-between" mt={2}>
                <Status value={item.status} />
                {item.overdueText ? (
                  <Stack gap={0.5}>
                    <Icon
                      name="alert"
                      color={theme => theme.palette.red[500]}
                      size={20}
                    />
                    <Typography
                      variant="captionBold"
                      color={theme => theme.palette.red[500]}
                      component="span"
                    >
                      {item.overdueText}
                    </Typography>
                  </Stack>
                ) : item.reviewDueDate ? (
                  <Typography variant="caption">
                    {shouldShowDue && <span>Due: </span>}
                    <Typography
                      variant="caption"
                      color={theme => theme.palette.grey[700]}
                      component="span"
                    >
                      {formatTime(item.reviewDueDate)}
                    </Typography>
                  </Typography>
                ) : null}
              </Stack>
            </DocumentItem>
          </Grid>
        );
      })}
    </Grid>
  );
}
