import {
  ABOUT_TO_EXPIRE_DURATION_MILISECONDS,
  DOCUMENT_SECTION_TEXT,
  DOCUMENT_STATUS_STEP_AND,
  DOCUMENT_STATUS_STEP_OR,
  DOCUMENT_TYPE_TEXT,
} from '@/constants';
import {
  AccountTypeDetails,
  AppConfigurations,
  Client,
  Document,
  DocumentSection,
  DocumentStatus,
  DocumentType,
  EmploymentStatus,
  Option,
  RequiredCondition,
  RequiredDocument,
  RequiredDocumentGroup,
  RequiredDocumentSection,
  SigningDocument,
  SigningFormType,
  Tenant,
} from '@/types';
import { getTimeDuration } from './time';
import { createMockDocument } from '@/test-utils/createMock';
import {
  isExcelContentType,
  isImageContentType,
  isPDFContentType,
} from './string';
import { isEmploymentInfoCollected } from './clients';
import { getSortOptionsFn } from './common';

export function standardizeClientDocuments(documents?: Document[]) {
  return (documents || []).map(item => {
    let status = item.status;
    let overdueText: string | undefined;

    if (item.expiryDate && !isInactiveDocument(item)) {
      const timeLeft =
        new Date(item.expiryDate).getTime() - new Date().getTime();

      if (timeLeft <= 0) {
        status = DocumentStatus.Expired;
      } else if (timeLeft <= ABOUT_TO_EXPIRE_DURATION_MILISECONDS) {
        status = DocumentStatus.AboutToExpire;
      }
    }

    if (
      [DocumentStatus.Pending, DocumentStatus.InReview].includes(status) &&
      item.reviewDueDate
    ) {
      const timeLeft =
        new Date(item.reviewDueDate).getTime() - new Date().getTime();

      if (timeLeft <= 0) {
        overdueText = `${getTimeDuration(item.reviewDueDate, new Date().getTime())} overdue`;
      }
    }

    return {
      ...item,
      status,
      overdueText,
      docType: item.docType || DocumentType.Unknown,
    };
  });
}

export function getRequiredDocumentByType(
  documentsInSection: Document[],
  requiredDocumentSection: RequiredDocumentSection,
) {
  const documentByType = groupDocuments(documentsInSection);
  const requiredDocumentDict: Partial<Record<DocumentType, RequiredDocument>> =
    {};
  requiredDocumentSection.docs.forEach(typeGroup => {
    typeGroup.split('|').forEach(typeText => {
      const type = typeText as DocumentType;

      requiredDocumentDict[type] = {
        name: type,
        displayName: DOCUMENT_TYPE_TEXT[type as string] || type || 'Document',
        status: documentByType[type]?.status || DocumentStatus.Pending,
      };
    });
  });

  return requiredDocumentDict;
}

export function getRequiredDocumentSectionDetails({
  documentsInSection,
  requiredSection,
}: {
  documentsInSection?: Document[];
  requiredSection?: RequiredDocumentSection;
}): Partial<RequiredDocumentSection> {
  if (!requiredSection) {
    return {
      status: getDocumentSectionStatus(documentsInSection),
    };
  }

  const requiredDocumentByType = getRequiredDocumentByType(
    documentsInSection || [],
    requiredSection,
  ) as Record<string, RequiredDocument>;

  const orOfDocsGroups: RequiredDocumentGroup[] = [];
  const allOfDocs: RequiredDocumentGroup = {
    condition: RequiredCondition.And,
    items: [],
    status: DocumentStatus.Pending,
  };

  requiredSection.docs.forEach(typeGroup => {
    if (!typeGroup.includes('|')) {
      if (requiredDocumentByType[typeGroup]) {
        allOfDocs.items.push(requiredDocumentByType[typeGroup]);
      }
    } else {
      const orOfDocs = typeGroup
        .split('|')
        .map(typeText => requiredDocumentByType[typeText]);

      orOfDocsGroups.push({
        condition: RequiredCondition.Or,
        items: orOfDocs,
        status: getHigherStatus(orOfDocs.map(item => item.status)),
      });
    }
  });

  allOfDocs.status = getHigherStatus(
    allOfDocs.items.map(item => item.status),
    RequiredCondition.And,
  );

  const groups = [
    ...(allOfDocs.items.length ? [allOfDocs] : []),
    ...(orOfDocsGroups.length ? orOfDocsGroups : []),
  ];

  return {
    ...requiredSection,
    groups,
    status: getHigherStatus(
      groups.map(item => item.status),
      RequiredCondition.And,
    ),
  };
}

export function getDocumentSigningSectionDetails(
  formsToSign: SigningDocument[],
  clientSignDocuments: Document[],
): RequiredDocumentSection {
  const status = getDocumentSectionStatus(
    clientSignDocuments,
    formsToSign.length,
  );

  return {
    type: DocumentSection.DocumentSign,
    docs: [],
    groups: [
      {
        condition: RequiredCondition.And,
        items: formsToSign.map(item => ({
          name: (item.formName || item.name) as DocumentType,
          displayName: item.name,
          status,
        })),
        status,
      },
    ],
    status,
  };
}

export function getProofOfEmploymentSectionDetails({
  requiredSection,
  documentsInSection,
  client,
  tenant,
}: {
  documentsInSection: Document[];
  requiredSection: RequiredDocumentSection;
  client: Client;
  tenant: Tenant;
}) {
  if (isEmploymentInfoCollected(client) && !requiredSection?.docs?.length) {
    let calculatedStatus: DocumentStatus = DocumentStatus.InReview;

    if (
      tenant === Tenant.Guyana &&
      client.employmentStatus === EmploymentStatus.Unemployed
    ) {
      calculatedStatus = DocumentStatus.Approved;
    }

    if (client.selfEmployedRiskSectorFlag) {
      calculatedStatus = DocumentStatus.InReview;
    }

    return {
      ...requiredSection,
      status: requiredSection?.status || calculatedStatus,
    };
  }

  return getRequiredDocumentSectionDetails({
    documentsInSection,
    requiredSection,
  });
}

export function getHigherStatusDocument(
  documents: Document[],
  condition: RequiredCondition = RequiredCondition.Or,
) {
  let result: Document | undefined;

  for (const doc of documents) {
    if (!result) {
      result = doc;
      continue;
    }
    const currentStatus = result.status;
    const stepObj =
      condition === RequiredCondition.And
        ? DOCUMENT_STATUS_STEP_AND
        : DOCUMENT_STATUS_STEP_OR;
    const currentStep = stepObj[currentStatus] || 99;
    const step = stepObj[String(doc.status)] || 99;

    const difference = step - currentStep;

    if (difference < 0) {
      result = doc;
    }
  }

  return result as Document;
}

export function getHigherStatus(
  statuses: (DocumentStatus | undefined)[],
  condition: RequiredCondition = RequiredCondition.Or,
) {
  let result: DocumentStatus | undefined;

  for (const status of statuses) {
    if (!result) {
      result = status;
      continue;
    }
    const currentStatus = result;
    const stepObj =
      condition === RequiredCondition.And
        ? DOCUMENT_STATUS_STEP_AND
        : DOCUMENT_STATUS_STEP_OR;
    const currentStep = stepObj[currentStatus] || 99;
    const step = stepObj[String(status)] || 99;

    const difference = step - currentStep;

    if (difference < 0) {
      result = status;
    }
  }

  return result as DocumentStatus;
}

export function groupDocuments(
  documents: Document[],
  groupBy: 'type' | 'form-id' = 'type',
) {
  const documentByGroup: Record<string, Document> = {};

  for (const doc of documents) {
    const groupKey =
      (groupBy === 'type' ? doc.docType : doc.adobeSignWidgetId) || 'untitled';

    if (!documentByGroup[groupKey]) {
      documentByGroup[groupKey] = doc;
    } else {
      documentByGroup[groupKey] = getHigherStatusDocument(
        [doc, documentByGroup[groupKey]],
        RequiredCondition.Or,
      );
    }
  }

  return documentByGroup;
}

export function groupDocumentsWithoutMerge(
  documents: Document[],
  groupBy: 'type' | 'form-id' = 'type',
) {
  const documentsByGroup: Record<string, Document[]> = {};

  for (const doc of documents) {
    const groupKey =
      (groupBy === 'type' ? doc.docType : doc.adobeSignWidgetId) || 'untitled';

    if (!documentsByGroup[groupKey]) {
      documentsByGroup[groupKey] = [doc];
    } else {
      documentsByGroup[groupKey].push(doc);
    }
  }

  return documentsByGroup;
}

export function getDocumentSectionStatus(
  documentsWithSameSection: Document[] = [],
  requiredAmount = 1,
) {
  if (!documentsWithSameSection.length) return DocumentStatus.Pending;

  const documentByType = groupDocuments(documentsWithSameSection);
  const nonDuplicatedDocuments = Object.values(documentByType);

  if (requiredAmount < 1) return DocumentStatus.Pending;

  if (requiredAmount === 1) {
    return getHigherStatusDocument(nonDuplicatedDocuments, RequiredCondition.Or)
      ?.status;
  }

  while (nonDuplicatedDocuments.length < requiredAmount) {
    nonDuplicatedDocuments.push(
      createMockDocument({
        status: DocumentStatus.Pending,
      }),
    );
  }

  const status = getHigherStatusDocument(
    nonDuplicatedDocuments,
    RequiredCondition.And,
  )?.status;

  return [DocumentStatus.Signing].includes(status as DocumentStatus)
    ? DocumentStatus.Pending
    : status;
}

export function getSignedDocuments(
  documentsByType: Document[] = [],
  formsToSign: SigningDocument[] = [],
) {
  if (!formsToSign.length) return [];

  const formIdsToSign = formsToSign.map(doc => doc.id);

  const result: Document[] = [];

  for (const doc of documentsByType) {
    if (
      !doc.adobeSignWidgetId ||
      !formIdsToSign.includes(doc.adobeSignWidgetId)
    )
      continue;

    if (
      [
        DocumentStatus.InReview,
        DocumentStatus.Pending,
        DocumentStatus.Signing,
        DocumentStatus.Approved,
      ].includes(doc.status)
    ) {
      result.push(doc);
    }
  }

  return result;
}

export function isInactiveDocument(document: Document) {
  return (
    [
      DocumentSection.BranchApproval,
      DocumentSection.Internal,
      DocumentSection.Generated,
    ].includes(document.type) ||
    [
      DocumentStatus.Approved,
      DocumentStatus.Rejected,
      DocumentStatus.Requested,
    ].includes(document.status)
  );
}

export function standardizeDocumentsToSign(
  signingDocs: SigningDocument[],
  accountTypes: AccountTypeDetails[],
) {
  const authForm = signingDocs.find(item => /authorization/i.test(item.name));
  if (!authForm || !accountTypes.length) return signingDocs;

  const nonAuthForms = signingDocs.filter(
    item => !/authorization/i.test(item.name),
  );

  return [
    ...(authForm && accountTypes.length
      ? accountTypes.map(
          accountType =>
            ({
              ...authForm,
              name: `${authForm.name} (${accountType.currency} ${accountType.name})`,
              formName: `${authForm.name}|${accountType.type}-${accountType.classCode}-${accountType.currency}`,
            }) as SigningDocument,
        )
      : []),
    ...nonAuthForms.map(item => ({
      ...item,
      formName: item.name,
    })),
  ];
}

export function isViewSupportedDocument(doc: Document) {
  return (
    isImageContentType(doc.contentType) ||
    isPDFContentType(doc.contentType) ||
    isExcelContentType(doc.contentType)
  );
}

export function getDocumentTypesBySection(
  section: DocumentSection,
  options: { formsToSign?: SigningDocument[] } = {},
) {
  const { formsToSign = [] } = options;

  if (section === DocumentSection.DocumentSign) {
    return formsToSign
      .map(form => ({
        value: form.formName || form.name,
        text: form.name,
      }))
      .sort((a, b) => (a.text || '').localeCompare(b.text || ''));
  }

  let types: DocumentType[] = [];

  if (section === DocumentSection.Identity) {
    types = [
      DocumentType.IdentityDriverLicense,
      DocumentType.Id,
      DocumentType.Passport,
      DocumentType.Nis,
    ];
  } else if (section === DocumentSection.ProofOfAddress) {
    types = [
      DocumentType.UtilityBill,
      DocumentType.UtilityBillNonOwner,
      DocumentType.BankStatement,
      DocumentType.GemeenteUitreksel,
      DocumentType.CertificateOfTitle,
      DocumentType.FireInsurance,
      DocumentType.RatesAndTaxesReceiptInvoice,
      DocumentType.TinCertificate,
      DocumentType.LetterOfAuthorisationFromUtilityBillOwner,
      DocumentType.PostMarkedEnvelopeWithCustomerNameAndAddress,
      DocumentType.LeaseAgreement,
      DocumentType.LetterFromToshaosHinterlandLocations,
      DocumentType.RegistrationForm,
      DocumentType.CBBExtract,
      DocumentType.SelfAttestationForProofOfAddress,
      DocumentType.CopyOfUtilityBillOwnerID,
      DocumentType.ProofOfPropertyOwnershipNotarialDeed,
      DocumentType.BankReferenceLetter,
      DocumentType.ConfirmationOfAddress,
    ];
  } else if (section === DocumentSection.TIN) {
    types = [DocumentType.DriverLicense, DocumentType.TinCertificate];
  } else if (section === DocumentSection.ProofOfEmployment) {
    types = [
      DocumentType.LetterOfEmployment,
      DocumentType.RecentPayslip,
      DocumentType.TradeLicense,
      DocumentType.BankReferenceLetter,
      DocumentType.LatestFiledTaxReturn,
      DocumentType.LeaseAgreement,
      DocumentType.FinancialStatements,
      DocumentType.IncomeStatement,
      DocumentType.ForecastProjections,
      DocumentType.FinancialStatementsProjections,
      DocumentType.ProjectedCashFlows,
      DocumentType.IncomeAndExpenditureStatement,
      DocumentType.SchoolLetterOfAttendance,
      DocumentType.SupportLetterFromCaretaker,
      DocumentType.SupportLetterFromHusband,
      DocumentType.SupportLetterFromParent,
      DocumentType.ParentID,
      DocumentType.AuthorisationLetter,
      DocumentType.SocialSecurityLetter,
      DocumentType.SelfAttestationForProofOfIncome,
      DocumentType.ProfessionalRecommendationLetter,
      DocumentType.ExtractFromChamberOfCommerce,
    ];
  } else if (section === DocumentSection.Internal) {
    types = [DocumentType.AML5, DocumentType.IDC, DocumentType.OneCardOrder];
  } else if (section === DocumentSection.BranchApproval) {
    types = [DocumentType.None];
  } else {
    types = [DocumentType.None];
  }

  return types
    .map(type => ({
      value: type,
      text: DOCUMENT_TYPE_TEXT[type],
      groupOrder: type === DocumentType.None ? 0 : 1,
    }))
    .sort((a, b) =>
      a.groupOrder !== b.groupOrder
        ? a.groupOrder - b.groupOrder
        : (a.text || '').localeCompare(b.text || ''),
    );
}

export function getSignFormType(form: SigningDocument) {
  if (/authorization/i.test(form.name)) {
    return SigningFormType.Authorization;
  } else if (/aml4/i.test(form.name)) {
    return SigningFormType.AML4;
  } else if (/aml6/i.test(form.name)) {
    return SigningFormType.AML6;
  } else if (/ca43a/i.test(form.name)) {
    return SigningFormType.CA43A;
  } else if (/fatca 1a/i.test(form.name)) {
    return SigningFormType.FATCA_1A;
  } else if (/w-9/i.test(form.name)) {
    return SigningFormType.W9;
  }
}

export function getDocumentSectionsToUpload(
  tenantConfigs: AppConfigurations & { tenant: Tenant },
) {
  const { tenant, requireLocalTin } = tenantConfigs;
  const sections: Option[] = [
    {
      value: DocumentSection.Identity,
      text:
        DOCUMENT_SECTION_TEXT[DocumentSection.Identity]?.[tenant] ||
        DOCUMENT_SECTION_TEXT[DocumentSection.Identity]?.default,
    },
    {
      value: DocumentSection.DocumentSign,
      text:
        DOCUMENT_SECTION_TEXT[DocumentSection.DocumentSign]?.[tenant] ||
        DOCUMENT_SECTION_TEXT[DocumentSection.DocumentSign]?.default,
    },
    ...(requireLocalTin
      ? [
          {
            value: DocumentSection.TIN,
            text:
              DOCUMENT_SECTION_TEXT[DocumentSection.TIN]?.[tenant] ||
              DOCUMENT_SECTION_TEXT[DocumentSection.TIN]?.default,
          },
        ]
      : []),
    {
      value: DocumentSection.IdentityBack,
      text:
        DOCUMENT_SECTION_TEXT[DocumentSection.IdentityBack]?.[tenant] ||
        DOCUMENT_SECTION_TEXT[DocumentSection.IdentityBack]?.default,
    },
    {
      value: DocumentSection.ProofOfAddress,
      text:
        DOCUMENT_SECTION_TEXT[DocumentSection.ProofOfAddress]?.[tenant] ||
        DOCUMENT_SECTION_TEXT[DocumentSection.ProofOfAddress]?.default,
    },
    {
      value: DocumentSection.ProofOfEmployment,
      text:
        DOCUMENT_SECTION_TEXT[DocumentSection.ProofOfEmployment]?.[tenant] ||
        DOCUMENT_SECTION_TEXT[DocumentSection.ProofOfEmployment]?.default,
    },
    {
      value: DocumentSection.Selfie,
      text:
        DOCUMENT_SECTION_TEXT[DocumentSection.Selfie]?.[tenant] ||
        DOCUMENT_SECTION_TEXT[DocumentSection.Selfie]?.default,
    },
    {
      value: DocumentSection.Internal,
      text:
        DOCUMENT_SECTION_TEXT[DocumentSection.Internal]?.[tenant] ||
        DOCUMENT_SECTION_TEXT[DocumentSection.Internal]?.default,
    },
    {
      value: DocumentSection.BranchApproval,
      text:
        DOCUMENT_SECTION_TEXT[DocumentSection.BranchApproval]?.[tenant] ||
        DOCUMENT_SECTION_TEXT[DocumentSection.BranchApproval]?.default,
    },
  ].sort(getSortOptionsFn());

  return sections;
}
