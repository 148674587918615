import { Grid, Typography } from '@mui/material';
import { DocumentAccordionPendingItemProps } from './DocumentAccordion.interface';
import { DocumentAccordionItemContainer } from './DocumentAccordion.styled';
import { Status } from '../Status';
import { DocumentStatus } from '@/types';

export function DocumentAccordionPendingItem({
  title,
}: DocumentAccordionPendingItemProps) {
  return (
    <DocumentAccordionItemContainer>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12}>
          <Typography variant="body2Medium" mb={0.75}>
            {title}
          </Typography>
          <Status size="small" value={DocumentStatus.Pending} display="block" />
        </Grid>
      </Grid>
    </DocumentAccordionItemContainer>
  );
}
