import {
  AMLStatus,
  ApiFilter,
  OperationReviewForm,
  ApproveBasicInfoForm,
  AssignClientForm,
  Client,
  ClientStatus,
  DataList,
  DocumentRequestForm,
  JobStatus,
  PEPRecord,
  PEPStatus,
  UpdateRiskLevelForm,
  PepAnswer,
  AccountType,
  AddCommentForm,
  Note,
  DocumentType,
  Document,
  SimilarAccount,
  ExportClientsForm,
  DocumentSection,
  RequiredDocumentSection,
  Occupation,
  Option,
} from '@/types';
import { axios } from './axios';
import {
  REQUEST,
  ClientDBFields,
  CLIENT_STATUS_STEP,
  ExportClientDBFields,
} from '@/constants';
import {
  downloadFile,
  getRequestFilterParams,
  getSortOptionsFn,
  standardizeClientDocuments,
} from '@/utils';
import { shortPolling } from './common';
import { getJobById } from './job';

export const getClients = async (filter: ApiFilter) => {
  const updatedFilter = { ...filter };
  if (updatedFilter.filter?.status) {
    updatedFilter.filter.status = updatedFilter.filter?.status.map(item =>
      item === ClientStatus.Draft
        ? `${ClientStatus.Draft}|${ClientStatus.InReview}`
        : item,
    );
  }
  const params = getRequestFilterParams(filter, ClientDBFields);
  const result = await axios.get<DataList<Omit<Client, 'documentStatuses'>>>(
    REQUEST.CLIENT.ROOT,
    {
      params,
    },
  );

  return {
    ...result.data,
    data: result.data.data.map(client => {
      const clientDocuments = standardizeClientDocuments(client.documents);

      const documentStatusCount: Record<string, number> = {};

      clientDocuments?.forEach(item => {
        if (!documentStatusCount[item.status]) {
          documentStatusCount[item.status] = 1;
        } else {
          documentStatusCount[item.status] += 1;
        }
      });

      return {
        ...client,
        status:
          client.status === ClientStatus.InReview
            ? ClientStatus.Draft
            : client.status,
        documentStatuses: Object.entries(documentStatusCount)
          .map(([status, count]) => ({
            value: status,
            count,
          }))
          .sort(
            (a, b) =>
              (CLIENT_STATUS_STEP[a.value] || 0) -
                CLIENT_STATUS_STEP[b.value] || 0,
          ),
      } as Client;
    }),
  } as DataList<Client>;
};

export const getClient = async (id: Client['id']) => {
  const result = await axios.get<Client>(REQUEST.CLIENT.INFO(id));
  const client = result.data;

  const standardizedDocuments = standardizeClientDocuments(client.documents);

  let requiredSections: RequiredDocumentSection[] = [];
  try {
    const requiredSectionsResult = await axios.get<RequiredDocumentSection[]>(
      REQUEST.CLIENT.REQUIRED_DOCUMENTS(id),
    );
    requiredSections = requiredSectionsResult.data;

    requiredSections = requiredSections.map(section => {
      const hasUnknownTypeDocs =
        (standardizedDocuments || []).filter(
          i => i.type === section.type && i.docType === DocumentType.Unknown,
        ).length > 0;

      if (hasUnknownTypeDocs) {
        return {
          ...section,
          docs: [...section.docs, DocumentType.Unknown],
        };
      }

      return section;
    });
  } catch (e) {
    console.error(e);
  }

  const pepAnswerObj =
    typeof client.pepAnswer?.answer === 'string'
      ? JSON.parse(client.pepAnswer.answer)
      : client.pepAnswer?.answer;

  return {
    ...client,
    status:
      client.status === ClientStatus.InReview
        ? ClientStatus.Draft
        : client.status,
    requiredDocumentSections: requiredSections,
    documents: standardizedDocuments,
    pepAnswer: client.pepAnswer
      ? {
          ...client.pepAnswer,
          answer: {
            answers: pepAnswerObj.answers.map(
              (item: PepAnswer) =>
                ({
                  ...item,
                  isNo: !item.positive,
                }) as PepAnswer,
            ),
          },
        }
      : undefined,
  } as Client;
};

export const createICBProfile = async (clientId: Client['id']) => {
  const result = await axios.post<{ jobId: string }>(
    REQUEST.CLIENT.CREATE_ICB_PROFILE(clientId),
  );

  const job = await shortPolling(
    () => getJobById(result.data.jobId),
    result => {
      return [JobStatus.Success, JobStatus.Error].includes(result.status);
    },
    {
      immediate: false,
      intervalDurationMs: 5000,
      maxAttempts: 5,
    },
  );

  return job;
};

export const createBankProfile = async (clientId: Client['id']) => {
  const result = await axios.post<{ jobId: string }>(
    REQUEST.CLIENT.CREATE_BANK_PROFILE(clientId),
  );

  return result.data;
};

export const updateClient = async (
  payload: { id: Client['id'] } & Partial<Client>,
) => {
  const result = await axios.patch(REQUEST.CLIENT.INFO(payload.id), payload);

  return result.data;
};

export const getPepResult = async (clientId: string) => {
  const result = await axios.get<{
    pepCheckStatus: PEPStatus;
    amlCheckStatus: AMLStatus;
  }>(REQUEST.CLIENT.PEP(clientId));

  return result.data;
};

export const triggerPepCheck = async (clientId: string) => {
  const result = await axios.post(REQUEST.CLIENT.PEP_CHECK(clientId));

  return result.data;
};

export const assignClient = async ({
  clientId,
  reviewerId,
}: AssignClientForm) => {
  const result = await axios.put(REQUEST.CLIENT.ASSIGN(clientId), {
    userId: reviewerId,
  });

  return result.data;
};

export const approveClient = async ({
  clientId,
  message,
  operationId,
}: OperationReviewForm) => {
  const result = await axios.post(REQUEST.CLIENT.OPERATION_REVIEW(clientId), {
    operationId,
    message,
    status: ClientStatus.Approved,
  });

  return result.data;
};

export const rejectClient = async ({
  clientId,
  message,
  operationId,
}: OperationReviewForm) => {
  const result = await axios.post(REQUEST.CLIENT.OPERATION_REVIEW(clientId), {
    operationId,
    message,
    status: ClientStatus.Rejected,
  });

  return result.data;
};

export const approveBasicInfo = async ({
  clientId,
  answerId,
}: ApproveBasicInfoForm) => {
  const result = await axios.post(
    REQUEST.CLIENT.APPROVE_BASIC_INFO(clientId, answerId),
  );

  return result.data;
};

export const getPEPInfo = async ({ clientId }: { clientId: Client['id'] }) => {
  const result = await axios.get<PEPRecord>(
    REQUEST.CLIENT.PEP_DETAILS(clientId),
  );

  return result.data;
};

export const approvePEPCheck = async ({
  clientId,
  pepRecordId,
}: {
  clientId: Client['id'];
  pepRecordId: string;
}) => {
  const result = await axios.post(
    REQUEST.CLIENT.APPROVE_PEP_CHECK(clientId, pepRecordId),
  );

  return result.data;
};

export const updateRiskLevel = async ({
  clientId,
  riskLevel,
}: UpdateRiskLevelForm) => {
  const result = await axios.put(REQUEST.CLIENT.RISK_LEVEL(clientId), {
    riskLevel,
  });

  return result.data;
};

export const requestClientDocument = async ({
  clientId,
  type,
  message,
}: DocumentRequestForm) => {
  const result = await axios.post(REQUEST.CLIENT.REQUEST_DOCUMENT(clientId), {
    type,
    message,
  });

  return result.data;
};

export const getClientAccountTypes = async (clientId: Client['id']) => {
  const result = await axios.get<AccountType[]>(
    REQUEST.CLIENT.BANK_ACCOUNTS(clientId),
  );

  return result.data;
};

export const addComment = async ({
  clientId,
  payload,
}: {
  clientId: string;
  payload: AddCommentForm;
}) => {
  const result = await axios.post(REQUEST.CLIENT.COMMENTS(clientId), payload);

  return result.data;
};

export const getComments = async (category: string, clientId: Client['id']) => {
  const result = await axios.get<Note[]>(REQUEST.CLIENT.COMMENTS(clientId), {
    params: {
      category,
    },
  });

  return result.data;
};

export const deleteComment = async ({
  noteId,
  clientId,
}: {
  noteId: Note['id'];
  clientId: Client['id'];
}) => {
  const result = await axios.delete(
    REQUEST.CLIENT.COMMENT_DETAILS(clientId, noteId),
  );

  return result.data;
};

export const editComment = async ({
  noteId,
  clientId,
  payload,
}: {
  noteId: Note['id'];
  clientId: Client['id'];
  payload: AddCommentForm;
}) => {
  const result = await axios.put(
    REQUEST.CLIENT.COMMENT_DETAILS(clientId, noteId),
    payload,
  );

  return result.data;
};

export const addClientDocument = async (payload: {
  clientId: Client['id'];
  file: File;
  section: DocumentSection;
  type?: DocumentType;
  adobeSignWidgetId?: Document['adobeSignWidgetId'];
}) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  formData.append('type', payload.section);
  formData.append('docType', payload.type || '');
  formData.append('adobeSignWidgetId', payload.adobeSignWidgetId || '');

  const result = await axios.post(
    REQUEST.CLIENT.DOCUMENTS(payload.clientId),
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return result.data;
};

export const getSimilarAccounts = async (payload: {
  firstName: string;
  lastName: string;
  birthday: string;
  selfRim?: string;
}) => {
  const { selfRim, ...rest } = payload;

  const result = await axios.post<SimilarAccount[]>(
    REQUEST.CLIENT.SIMILAR_ACCOUNTS,
    rest,
  );

  return result.data.filter(i => !selfRim || i.rimNo !== selfRim);
};

export const reactivateApplication = async ({
  clientId,
  operationId,
}: {
  clientId: Client['id'];
  operationId: string;
}) => {
  const result = await axios.put(
    REQUEST.CLIENT.REACTIVATE(clientId, operationId),
  );

  return result.data;
};

export const downloadExportedClients = async (payload: ExportClientsForm) => {
  const { applicationStatuses, dateFrom, dateTo, ...fieldsObj } = payload;

  const params = getRequestFilterParams(
    {
      dateFrom,
      dateTo,
      filter: {
        status: applicationStatuses,
      },
      fields: Object.entries(fieldsObj)
        .filter(([, value]) => !!value)
        .map(([key]) => key),
    },
    ExportClientDBFields,
  );

  const result = await axios.get<Blob>(REQUEST.CLIENT.EXPORT, {
    params,
    responseType: 'blob',
  });

  const url = URL.createObjectURL(result.data);
  downloadFile(url, `exported-clients`);
};

export const getOccupationOptions = async () => {
  const result = await axios.get<Occupation[]>(REQUEST.DICTIONARY.ROOT, {
    params: {
      type: 'OCCUPATION',
    },
  });

  return result.data
    .map(
      ({ code, value }) =>
        ({
          value: code,
          text: value,
        }) as Option,
    )
    .sort(getSortOptionsFn('asc'));
};
