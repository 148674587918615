import { InputAdornment, Stack, TextFieldProps } from '@mui/material';
import { useCallback } from 'react';
import { Icon } from '../Icon';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RefCallBack,
  RegisterOptions,
} from 'react-hook-form';
import { DatePickerProps as MUIDatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { elevationLevel } from '@/styles';
import { FieldArrow, FieldArrowButton, StyledDatePicker } from './Form.styled';
import { useBoolean } from 'usehooks-ts';
import { useResponsive } from '@/hooks';
import { format, parseISO } from 'date-fns';
import { DATE_VALUE_FORMAT } from '@/constants';
import { getDataTestId } from '@/utils';

type DatePickerProps<FormPayload extends FieldValues> = {
  value?: Date;
  defaultValue?: Date;
  control: Control<FormPayload>;
  name: Path<FormPayload>;
  rules?: Omit<
    RegisterOptions<FormPayload, Path<FormPayload>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  helperText?: TextFieldProps['helperText'];
  disableInput?: boolean;
  error?: TextFieldProps['error'];
} & Omit<MUIDatePickerProps<Date>, 'slotProps' | 'name' | 'value'>;

export function DatePicker<FormPayload extends FieldValues>({
  control,
  rules,
  name,
  error,
  helperText,
  disableInput = false,
  ...props
}: DatePickerProps<FormPayload>) {
  const { value: open, setTrue: show, setFalse: hide } = useBoolean();
  const { isDesktop } = useResponsive();

  const onInputClick = useCallback(() => {
    if (!isDesktop && !props.disabled) show();
  }, [isDesktop, props.disabled, show]);

  const render = useCallback(
    ({
      value,
      onChange,
      onBlur,
      defaultValue,
      ref,
    }: {
      value: string;
      onChange: (value: string) => void;
      onBlur: TextFieldProps['onBlur'];
      defaultValue?: string;
      ref?: RefCallBack;
    }) => {
      const handleOnChange: DatePickerProps<Date>['onChange'] = (
        value: Date | null,
      ) => {
        onChange(value ? format(value, DATE_VALUE_FORMAT) : '');
      };

      return (
        <StyledDatePicker
          {...getDataTestId(`${name || 'date'}-picker`)}
          ref={ref}
          value={parseISO(value)}
          inputRef={ref}
          onChange={handleOnChange}
          defaultValue={defaultValue ? parseISO(defaultValue) : undefined}
          open={open}
          onClose={hide}
          reduceAnimations
          slotProps={{
            desktopPaper: {
              elevation: elevationLevel.l,
            },
            textField: {
              onBlur,
              onClick: onInputClick,
              helperText:
                error && helperText ? (
                  open ? null : (
                    <Stack gap={0.5} component="span">
                      <Icon
                        name="alert"
                        size={20}
                        color={theme => theme.palette.error.main}
                      />
                      {helperText}
                    </Stack>
                  )
                ) : (
                  helperText
                ),
              error: !open && error,
              inputProps: {
                disabled: disableInput,
              },
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon name="calendar-blank" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <FieldArrowButton onClick={show} disabled={props.disabled}>
                    <FieldArrow name={open ? 'arrow-up' : 'arrow-down'} />
                  </FieldArrowButton>
                ),
              },
            },
          }}
          {...props}
        />
      );
    },
    [error, helperText, hide, name, onInputClick, open, props, show, disableInput],
  );

  return (
    <Controller
      rules={rules}
      control={control}
      name={name}
      render={({
        field: { ref, onChange, value, onBlur },
        formState: { defaultValues },
      }) =>
        render({
          value,
          onChange,
          onBlur,
          defaultValue: defaultValues?.[name],
          ref,
        })
      }
    />
  );
}
