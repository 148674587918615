import {
  DocumentAccordion,
  Heading,
  ProgressSteps,
  Status,
} from '@/components';
import { useClientCalculation, useDispatch, useSelector } from '@/hooks';
import { selectClientDetails, showDocumentReviewDrawer } from '@/redux';
import { Document, DocumentSection } from '@/types';
import { Stack, Typography } from '@mui/material';
import { BasicInfoAccordion } from './BasicInfoAccordion';
import { AdditionalDocumentAccordion } from './AdditionalDocumentAccordion';
import { formatTime } from '@/utils';
import { DOCUMENT_TYPE_TEXT } from '@/constants';

export function SectionOnboarding() {
  const client = useSelector(selectClientDetails);
  const dispatch = useDispatch();

  const { groups, groupBySection, onboardProgress } = useClientCalculation();

  const onReviewClick = (document: Document) =>
    dispatch(
      showDocumentReviewDrawer({
        client,
        document,
      }),
    );

  const clientDocumentGroups = groups.filter(
    item =>
      ![
        DocumentSection.BranchApproval,
        DocumentSection.Internal,
        DocumentSection.Generated,
      ].includes(item.section),
  );

  const branchApprovalDocumentGroup =
    groupBySection[DocumentSection.BranchApproval];
  const internalDocumentGroup = groupBySection[DocumentSection.Internal];
  const generatedDocumentGroup = groupBySection[DocumentSection.Generated];

  return (
    <Stack direction="column" alignItems="stretch" gap={2}>
      <Heading
        isSubSection
        title="Onboarding"
        mb={0}
        actionSlot={
          <ProgressSteps
            width={240}
            value={onboardProgress.finished}
            total={onboardProgress.total}
          />
        }
      />
      <BasicInfoAccordion />
      {clientDocumentGroups.map(item => (
        <DocumentAccordion
          key={item.section}
          title={item.title}
          status={item.generalStatus}
          description={item.description}
          documents={item.items}
          documentGroups={item.requiredDocGroups}
          itemProps={{
            onReviewClick,
            renderDocumentItemTitle:
              item.section === DocumentSection.DocumentSign
                ? doc => (
                    <>
                      <Typography variant="body2Medium" mb={0.75}>
                        {doc.formName ||
                          DOCUMENT_TYPE_TEXT[doc.docType as string] ||
                          doc.docType ||
                          'Document'}
                      </Typography>
                      <Status size="small" value={doc.status} display="block" />
                    </>
                  )
                : undefined,
          }}
          disabled={
            !item.items.length &&
            !item.requiredDocGroups?.length &&
            !item?.documentAnsweredQuestions?.length
          }
          documentAnsweredQuestions={item.documentAnsweredQuestions}
        />
      ))}
      {client?.eligible === false && (
        <AdditionalDocumentAccordion
          client={client}
          title="Branch Approval Document"
          section={DocumentSection.BranchApproval}
          documents={branchApprovalDocumentGroup?.items || []}
        />
      )}
      {client && (
        <AdditionalDocumentAccordion
          title="Internal Document"
          client={client}
          section={DocumentSection.Internal}
          documents={internalDocumentGroup?.items || []}
        />
      )}
      {generatedDocumentGroup?.items.length && (
        <DocumentAccordion
          title={generatedDocumentGroup.title}
          status={generatedDocumentGroup.generalStatus}
          description={generatedDocumentGroup.description}
          documents={generatedDocumentGroup.items}
          disableDocumentCache
          itemProps={{
            onReviewClick,
            renderDocumentItemTime: item => (
              <>
                <Typography
                  variant="caption"
                  color={theme => theme.palette.grey[700]}
                >
                  Last updated
                </Typography>
                <Typography variant="body2">
                  {formatTime(item.updatedAt)}
                </Typography>
              </>
            ),
          }}
        />
      )}
    </Stack>
  );
}
