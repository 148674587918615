import { DocumentStatus } from '@/types';
import {
  AccordionSummary,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { Icon } from '../../Icon';
import { StatusIcon } from '../StatusIcon';
import { StatusText } from '@/constants';
import { getDataTestId } from '@/utils';
import {
  DocumentAccordionDetails,
  StyledAccordion,
} from './DocumentAccordion.styled';
import { useMemo } from 'react';
import { DocumentAccordionProps } from './DocumentAccordion.interface';
import { DocumentAccordionItem } from './DocumentAccordionItem';
import { DocumentAccordionAnsweredQuestion } from './DocumentAccordionAnsweredQuestion';
import { DocumentAccordionGroup } from './DocumentAccordionGroup';

export function DocumentAccordion({
  title,
  description,
  documents,
  documentGroups,
  status,
  statusIcon,
  children,
  defaultExpanded,
  disabled,
  disableDocumentCache = false,
  documentAnsweredQuestions,
  itemProps,
  renderDocumentItem,
}: DocumentAccordionProps) {
  const defaultExpandedValue = useMemo(
    () =>
      !!documents?.length ||
      !!documentGroups?.length ||
      !!documentAnsweredQuestions?.length ||
      defaultExpanded,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <StyledAccordion
      {...getDataTestId(`accordion-${title}`)}
      defaultExpanded={defaultExpandedValue}
      disabled={disabled}
    >
      <AccordionSummary
        expandIcon={
          <Icon name="arrow-down" color={theme => theme.palette.grey[700]} />
        }
      >
        <ListItem>
          <StatusIcon
            value={status || DocumentStatus.Pending}
            iconName={statusIcon}
          />
          <ListItemText>
            <Typography variant="body2Bold" mb={0.5}>
              {title}
            </Typography>
            <Typography
              variant="body2"
              color={theme => theme.palette.grey[700]}
            >
              {description || StatusText[status || ''] || status}
            </Typography>
          </ListItemText>
        </ListItem>
      </AccordionSummary>
      <DocumentAccordionDetails>
        {documentAnsweredQuestions?.length &&
          documentAnsweredQuestions.map(item => (
            <DocumentAccordionAnsweredQuestion
              key={item.question}
              question={item}
            />
          ))}
        {children}
        {documentGroups?.length
          ? documentGroups.map((group, index) => (
              <DocumentAccordionGroup
                key={`group-${index}`}
                group={group}
                documents={documents || []}
                disableDocumentCache={disableDocumentCache}
                itemProps={itemProps}
              />
            ))
          : (documents || []).map(item =>
              renderDocumentItem ? (
                renderDocumentItem(item)
              ) : (
                <DocumentAccordionItem
                  key={item.id}
                  item={item}
                  disableDocumentCache={disableDocumentCache}
                  {...itemProps}
                />
              ),
            )}
      </DocumentAccordionDetails>
    </StyledAccordion>
  );
}
