import { DocumentAccordionItemContainer } from './DocumentAccordion.styled';
import { Grid, Typography } from '@mui/material';
import { DocumentAccordionItemProps } from './DocumentAccordion.interface';
import { DOCUMENT_TYPE_TEXT } from '@/constants';
import { Status } from '../Status';
import { formatTime } from '@/utils';
import { Document, DocumentStatus, IconName, Option } from '@/types';
import { Can } from '../../Can';
import { MenuButton } from '../../Action';
import { useDownloadDocument } from '@/hooks';

export function DocumentAccordionItem({
  item,
  disableDocumentCache,
  onReviewClick,
  renderDocumentItemTitle,
  renderDocumentItemTime,
  renderDocumentItemMenuButton,
}: DocumentAccordionItemProps) {
  const downloadDocument = useDownloadDocument({
    disabledCache: disableDocumentCache,
  });

  const options: Option[] = [
    ...(onReviewClick
      ? [
          {
            text: 'Review',
            icon: 'search-doc' as IconName,
            value: 'review',
          },
        ]
      : []),
    {
      text: 'Download',
      icon: 'download',
      value: 'download',
    },
  ];

  const onOptionSelect = (document: Document, option: Option) => {
    switch (option.value) {
      case 'review':
        onReviewClick?.(document);
        break;
      case 'download':
        downloadDocument(document);
        break;
    }
  };

  return (
    <DocumentAccordionItemContainer>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          {renderDocumentItemTitle ? (
            renderDocumentItemTitle(item)
          ) : (
            <>
              <Typography variant="body2Medium" mb={0.75}>
                {DOCUMENT_TYPE_TEXT[item.docType as string] ||
                  item.docType ||
                  'Document'}
              </Typography>
              <Status size="small" value={item.status} display="block" />
            </>
          )}
        </Grid>
        <Grid item xs={4.5}>
          {renderDocumentItemTime ? (
            renderDocumentItemTime(item)
          ) : (
            <>
              {item.reviewDueDate && (
                <>
                  <Typography
                    variant="caption"
                    color={theme => theme.palette.grey[700]}
                  >
                    Due date
                  </Typography>
                  <Typography variant="body2">
                    {formatTime(item.reviewDueDate)}
                  </Typography>
                </>
              )}
            </>
          )}
        </Grid>
        <Grid item xs={1.5} textAlign="right">
          {item.status ===
          DocumentStatus.Pending ? null : renderDocumentItemMenuButton ? (
            renderDocumentItemMenuButton(item)
          ) : (
            <Can do="view" on="document">
              <MenuButton
                options={options}
                onSelect={option => onOptionSelect(item, option)}
              />
            </Can>
          )}
        </Grid>
      </Grid>
    </DocumentAccordionItemContainer>
  );
}
