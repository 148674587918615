import {
  SimplePaletteColorOptions,
  ThemeOptions,
  createTheme,
} from '@mui/material';
import {
  badgeGradient,
  blue,
  green,
  grey,
  orange,
  pink,
  purple,
  red,
  violet,
  yellow,
} from './constants/colors';
import {
  AMLStatus,
  CautionCheckStatus,
  ClientStatus,
  Color,
  DocumentStatus,
  GradientColor,
  PEPStatus,
  StatusColor,
  UserStatus,
} from '@/types';

declare module '@mui/material' {
  interface Color {
    1000: string;
    overlayOnLight: string;
    overlayOnDark: string;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    primary1000: SimplePaletteColorOptions;
    primary900: SimplePaletteColorOptions;
    primary800: SimplePaletteColorOptions;
    primary200: SimplePaletteColorOptions;
    primaries: Color;
    secondaryPink: Color;
    secondaryPurple: Color;
    secondaryViolet: Color;
    yellow: Color;
    green: Color;
    red: Color;
    orange: Color;
    badgeBackground: GradientColor;
    status: Record<string, StatusColor>;
  }
  interface PaletteOptions {
    primary1000: SimplePaletteColorOptions;
    primary900: SimplePaletteColorOptions;
    primary800: SimplePaletteColorOptions;
    primary200: SimplePaletteColorOptions;
    primaries: Color;
    secondaryPink: Color;
    secondaryPurple: Color;
    secondaryViolet: Color;
    yellow: Color;
    green: Color;
    red: Color;
    orange: Color;
    badgeBackground: GradientColor;
    status: Record<string, StatusColor>;
  }
}

const {
  palette: { augmentColor },
} = createTheme();

export const paletteConfigs: ThemeOptions['palette'] = {
  mode: 'light',
  primary: augmentColor({ color: blue }),
  primary1000: augmentColor({ color: blue, mainShade: 1000 }),
  primary900: augmentColor({ color: blue, mainShade: 900 }),
  primary800: augmentColor({ color: blue, mainShade: 800 }),
  primary200: augmentColor({ color: blue, mainShade: 200 }),
  secondary: augmentColor({ color: pink }),
  success: augmentColor({ color: green }),
  error: augmentColor({ color: red }),
  warning: augmentColor({ color: yellow }),
  info: augmentColor({ color: blue }),
  primaries: blue,
  secondaryPink: pink,
  secondaryPurple: purple,
  secondaryViolet: violet,
  yellow: yellow,
  green: green,
  red: red,
  orange: orange,
  grey: grey,
  background: {
    default: grey[100],
    paper: grey.min,
  },
  common: {
    white: grey.min,
    black: grey.max,
  },
  text: {
    primary: grey[1000],
    secondary: grey[700],
    disabled: grey[700],
  },
  action: {
    disabledBackground: grey[200],
    disabled: grey[400],
  },
  badgeBackground: badgeGradient,
  status: {
    [ClientStatus.Archived]: {
      background: grey[200],
      text: grey[700],
    },
    [ClientStatus.PendingApproval]: {
      main: blue[500],
      background: blue[400],
      text: grey.min,
      icon: blue[500],
      iconBackground: blue[200],
    },
    [ClientStatus.Draft]: {
      main: orange[600],
      background: orange[100],
      text: orange[600],
      icon: orange[600],
      iconBackground: orange[200],
    },
    [DocumentStatus.AboutToExpire]: {
      main: orange[600],
      background: orange[100],
      text: orange[600],
      icon: orange[500],
      iconBackground: orange[200],
    },
    [DocumentStatus.Rejected]: {
      main: red[400],
      background: red[400],
      text: grey.min,
      icon: red[400],
      iconBorder: red[400],
    },
    [DocumentStatus.Approved]: {
      main: green[500],
      background: green[100],
      text: green[500],
      icon: green[500],
      iconBackground: green[200],
    },
    [DocumentStatus.Expired]: {
      main: red[500],
      background: red[500],
      text: grey.min,
      icon: red[500],
      iconBackground: red[200],
      iconBorder: red[500],
    },
    [DocumentStatus.Pending]: {
      main: blue[500],
      background: 'transparent',
      text: grey[700],
      border: grey[400],
      icon: grey[700],
      iconBackground: grey[200],
    },
    [DocumentStatus.Generated]: {
      main: blue[500],
      background: 'transparent',
      text: grey[700],
      border: grey[400],
      icon: grey[700],
      iconBackground: grey[200],
    },
    [DocumentStatus.InReview]: {
      main: blue[500],
      background: blue[400],
      text: grey.min,
      icon: blue[500],
      iconBackground: blue[200],
    },
    [DocumentStatus.Requested]: {
      main: blue[500],
      background: blue[100],
      text: blue[500],
    },
    [UserStatus.Active]: {
      main: green[500],
      background: green[100],
      text: green[500],
    },
    [UserStatus.Locked]: {
      main: red[400],
      background: red[400],
      text: grey.min,
    },
    [PEPStatus.Exposed]: {
      background: red[100],
      text: red[600],
    },
    [AMLStatus.Sanctioned]: {
      background: red[100],
      text: red[600],
    },
    [PEPStatus.Verified]: {
      background: green[100],
      text: green[500],
    },
    [PEPStatus.Approved]: {
      background: green[100],
      text: green[500],
    },
    [AMLStatus.Verified]: {
      background: green[100],
      text: green[500],
    },
    [AMLStatus.Approved]: {
      background: green[100],
      text: green[500],
    },
    [PEPStatus.InProgress]: {
      background: orange[100],
      text: orange[600],
    },
    [AMLStatus.InProgress]: {
      background: orange[100],
      text: orange[600],
    },

    [CautionCheckStatus.Matched]: {
      background: yellow[100],
      text: red[600],
    },
    [CautionCheckStatus.UnMatched]: {
      background: green[100],
      text: green[500],
    },
    verified: {
      background: green[100],
      text: green[500],
    },
    unverified: {
      background: orange[100],
      text: orange[600],
    },
  },
};
