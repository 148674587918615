import { AppConfigurations, DocumentType } from '@/types';
import { defaultConfigs } from './default';
import { DOCUMENT_TYPE_TEXT } from '../mapper';

export const grenadaConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-GD',
  isoCode: 'GRD',
  currency: 'XCD',
  name: 'Grenada',
  flag: 'flag-grenada',
  idDocumentTypes: [
    {
      value: DocumentType.Id,
      text: DOCUMENT_TYPE_TEXT[DocumentType.Id],
    },
    {
      value: DocumentType.Passport,
      text: DOCUMENT_TYPE_TEXT[DocumentType.Passport],
    },
    {
      value: DocumentType.Nis,
      text: DOCUMENT_TYPE_TEXT[DocumentType.Nis],
    },
  ],
};
