import { AppConfigurations } from '@/types';
import { defaultConfigs } from './default';

export const trinidadConfigs: AppConfigurations = {
  ...defaultConfigs,
  locale: 'en-TT',
  isoCode: 'TTO',
  currency: 'TTD',
  name: 'Trinidad',
  flag: 'flag-trinidad-and-tobago',
};
