import { User } from '@/types';
import { readLocal, saveLocal } from './browser';
import { appConfigs, LS_TENANT_KEY } from '@/constants';

export function getDefaultTenant(user?: User) {
  if (!user || !user.tenants?.length) return '';

  const savedTenant = readLocal(LS_TENANT_KEY);

  if (appConfigs[savedTenant] && user.tenants.includes(savedTenant)) {
    return savedTenant;
  } else if (user.tenants[0] && appConfigs[user.tenants[0]]) {
    saveLocal(LS_TENANT_KEY, user.tenants[0]);
    return user.tenants[0];
  } else {
    return '';
  }
}
