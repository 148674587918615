import {
  ApiFilter,
  DataList,
  Document,
  DocumentType,
  ReviewDocumentForm,
  SigningDocument,
} from '@/types';
import {
  formatDateValue,
  getRequestFilterParams,
  standardizeClientDocuments,
} from '@/utils';
import Axios from 'axios';
import { axios } from './axios';
import { DATE_VALUE_FORMAT, DocumentDBFields, REQUEST } from '@/constants';
export const getDocuments = async (filter: ApiFilter) => {
  const params = getRequestFilterParams(filter, DocumentDBFields);
  const { data: documentsResponse } = await axios.get<DataList<Document>>(
    REQUEST.DOCUMENT.ROOT,
    {
      params,
    },
  );

  return {
    ...documentsResponse,
    data: standardizeClientDocuments(documentsResponse.data),
  };
};

export const getDocumentBlobURL = async (url: string) => {
  const result = await axios.get(url, { responseType: 'blob' });
  return window.URL.createObjectURL(result.data);
};

export const getPresignedAwsUrl = async (uri: string) => {
  const { data } = await axios.get(REQUEST.DOCUMENT.PRESIGNED_URL(uri));
  return data.url;
};

export const reviewDocument = async (payload: ReviewDocumentForm) => {
  const result = await axios.post(
    REQUEST.CLIENT.REVIEW_DOCUMENT(payload.clientId),
    payload,
  );

  return result.data;
};

export const getSigningForms = async () => {
  const result = await axios.get<SigningDocument[]>(
    REQUEST.DOCUMENT.SIGNING_FORMS,
  );

  return result.data;
};

export const getLocalDocumentUrlFromS3 = async (uri: string) => {
  const result = await axios.get(REQUEST.DOCUMENT.DOWNLOAD_BY_S3, {
    params: {
      uri,
    },
    responseType: 'blob',
  });

  return URL.createObjectURL(result.data);
};

export const getLocalDocumentUrl = async (uri: string) => {
  const result = await Axios.get(uri, {
    responseType: 'blob',
  });

  return URL.createObjectURL(result.data);
};

export const getLocalPrivateDocumentUrl = async (uri: string) => {
  const result = await axios.get(uri, {
    responseType: 'blob',
  });

  return URL.createObjectURL(result.data);
};

export const updateDocument = async (payload: Document) => {
  const requestPayload = { ...payload };
  if (requestPayload.docType === DocumentType.Nis) {
    requestPayload.expiryDate = formatDateValue(
      '2079-06-06',
      DATE_VALUE_FORMAT,
    );
  }
  const result = await axios.put(
    REQUEST.DOCUMENT.INFO(requestPayload.id),
    requestPayload,
  );

  return result.data;
};

export const getRequestDocumentTemplate = async () => {
  const result = await axios.get<{ requestTemplate: string }>(
    REQUEST.DOCUMENT.REQUEST,
  );

  return result.data.requestTemplate;
};
