import { getReviewers, getRiskLevels } from '@/api';
import { getBranches } from '@/api/places';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchReviewers = createAsyncThunk(
  'picklists/get-reviewers',
  getReviewers,
);

export const fetchRiskLevels = createAsyncThunk(
  'picklists/get-risk-levels',
  getRiskLevels,
);

export const fetchBranches = createAsyncThunk(
  'picklists/get-branches',
  getBranches,
);

export const resetPicklists = createAction('picklists/reset');
