import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export function useNavigateBack() {
  const location = useLocation();
  const navigate = useNavigate();

  const navigateBack = useCallback(() => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate('..');
    }
  }, [location.key, navigate]);

  return navigateBack;
}
