export const ROUTE = {
  // Authenticated
  ROOT: '/',
  TENANT_HOME: ':tenant',
  HOME: '',
  CLIENTS: {
    index: 'clients',
    CLIENT_DETAILS: ':id',
  },
  DOCUMENTS: 'documents',
  REPORTS: 'reports',
  ACTIVITY_LOGS: 'activity-logs',
  CHANGE_PASSWORD: 'change-password',
  ADMINISTRATION: {
    index: 'administration',
    ROLE_DETAILS: 'role/:id',
  },
  // Unauthenticated
  LOGIN: 'login',
};

export const NAV_BAR_CONFIGS = [
  {
    to: ROUTE.HOME,
    text: 'Home',
    isComingSoon: false,
  },
  {
    to: ROUTE.CLIENTS.index,
    text: 'Clients',
    isComingSoon: false,
    permission: {
      do: 'view',
      on: 'client',
    },
  },
  {
    to: ROUTE.DOCUMENTS,
    text: 'Documents',
    isComingSoon: false,
    permission: {
      do: 'view',
      on: 'document',
    },
  },
  {
    to: ROUTE.ACTIVITY_LOGS,
    text: 'Activity Logs',
    isComingSoon: false,
    permission: {
      do: 'view',
      on: 'report',
    },
  },
  {
    to: ROUTE.ADMINISTRATION.index,
    text: 'Administration',
    isComingSoon: false,
    permission: {
      do: 'view',
      on: 'administration',
    },
  },
];
