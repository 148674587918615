import {
  AbilityContext,
  Can,
  ClientBlock,
  Filter,
  Icon,
  InfiniteTablePage,
  StackBar,
  Status,
  Switch,
  Table,
} from '@/components';
import {
  ClientStatus,
  Client,
  ModalName,
  DocumentStatus,
  ICBResponseCode,
} from '@/types';
import { Button, Stack } from '@mui/material';
import { CLIENT_STATUSES, ROUTE, StatusText } from '@/constants';
import {
  getDataTestId,
  getDisplayField,
  isInactiveClient,
  getStatusIcon,
  formatDate,
  shouldShowAssignButton,
  getNavigateObject,
} from '@/utils';
import { getClients } from '@/api';
import { useContext, useEffect } from 'react';
import { useDispatch, useInfiniteTable, useSelector } from '@/hooks';
import {
  clearModalRefreshState,
  selectAssignClientModalState,
  selectLoggedInUser,
  selectReviewers,
  showAssignClientModal,
  showExportClientDrawer,
} from '@/redux';
import { EditButton } from './Clients.styled';
import { ClientFilter } from './Clients.interface';
import { ClientsFilterBar } from './ClientsFilterBar';
import { AvatarProps } from '@/components/User/User.interface';
import { useNavigate } from 'react-router-dom';

export function ClientsPage() {
  const {
    rows: clients,
    total,
    debouncedFilter,
    control,
    setValue,
    watch,
    onSortChange,
    nextPageTriggerRef,
    error,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteTable<Client, ClientFilter>({
    defaultValues: getUrlValue => ({
      selfAssigned: getUrlValue('selfAssigned', 'boolean') as boolean,
      applicationStatuses: getUrlValue(
        'applicationStatuses',
        'array',
      ) as ClientStatus[],
      assignees: getUrlValue('assignees', 'array') as string[],
      dueFrom: getUrlValue('dueFrom', 'string') as string,
      dueTo: getUrlValue('dueTo', 'string') as string,
      from: getUrlValue('from', 'string') as string,
      to: getUrlValue('to', 'string') as string,
    }),
    queryFn: getClients,
    getQueryFilter: filter => ({
      filter: {
        status: filter.applicationStatuses?.map(i => String(i)) || [],
        assignee:
          filter.selfAssigned && loggedInUser?.id
            ? [loggedInUser?.id]
            : (filter.assignees?.filter(i => !!i) as string[]) || [],
      },
      dueFrom: filter.dueFrom,
      dueTo: filter.dueTo,
      from: filter.from,
      to: filter.to,
    }),
  });
  const ability = useContext(AbilityContext);
  const loggedInUser = useSelector(selectLoggedInUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reviewers = useSelector(selectReviewers);
  const { shouldRefreshData } = useSelector(selectAssignClientModalState);
  const sortBy = watch('sortBy');
  const selfAssigned = watch('selfAssigned');
  const assignees = watch('assignees');

  useEffect(() => {
    if (assignees?.length) {
      setValue('selfAssigned', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignees]);

  useEffect(() => {
    if (selfAssigned) {
      setValue('assignees', []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfAssigned]);

  useEffect(() => {
    if (shouldRefreshData) {
      refetch();
      dispatch(clearModalRefreshState(ModalName.AssignClient));
    }
  }, [dispatch, refetch, shouldRefreshData]);

  const showExportClient = () => dispatch(showExportClientDrawer());

  const onAssignClick = (targetClient: Client) => {
    if (targetClient) {
      dispatch(showAssignClientModal(targetClient));
    }
  };

  return (
    <InfiniteTablePage
      title="Clients"
      total={total}
      filterControl={control}
      error={error}
      fetchTriggerRef={nextPageTriggerRef}
      slots={{
        headingAction: (
          <Stack gap={1}>
            <Can do="export" on="client">
              <Button
                {...getDataTestId(`export-client-button`)}
                onClick={showExportClient}
                variant="outlined"
                startIcon={<Icon name="upload" />}
              >
                Export clients
              </Button>
            </Can>
          </Stack>
        ),
        quickFilter: (
          <Switch
            control={control}
            name="selfAssigned"
            label="Show only assigned to you"
          />
        ),
        filterBar: (
          <ClientsFilterBar
            currentFilters={debouncedFilter}
            onSetFilters={setValue}
          />
        ),
      }}
    >
      <Table
        sortBy={sortBy}
        isLoading={isFetching || isFetchingNextPage}
        rows={clients || []}
        onSortChange={onSortChange}
        columns={[
          {
            header: 'Client',
            widthRatio: 28,
            allowSort: true,
            dataKey: 'firstName',
            cell: row => (
              <ClientBlock
                clientId={row.id}
                avatar={row.picture}
                name={getDisplayField(row)}
                avatarProps={{
                  big: false,
                  ...(getStatusIcon(row) as AvatarProps),
                }}
                description={row.rim ? `RIM # ${row.rim}` : ''}
                onClick={() => {
                  ability.can('view', 'client') &&
                    navigate(
                      getNavigateObject(ROUTE.CLIENTS.CLIENT_DETAILS, {
                        params: { id: row.id },
                      }),
                    );
                }}
              />
            ),
          },
          {
            header: 'Status',
            dataKey: 'status',
            widthRatio: 14,
            cell: row => (
              <Stack direction="row" gap={1}>
                <Status value={row.status} />
                {row.status === ClientStatus.Draft &&
                  row?.icbResponseCode !== undefined &&
                  row?.icbResponseCode !== ICBResponseCode.Success && (
                    <Icon
                      name="warning"
                      color={theme => theme.palette.error.main}
                    />
                  )}
              </Stack>
            ),
            filter: (
              <Filter
                name="applicationStatuses"
                options={CLIENT_STATUSES}
                control={control}
              />
            ),
          },
          {
            header: 'Date',
            dataKey: 'createdAt',
            widthRatio: 12,
            cell: row => formatDate(row.createdAt, 'dd/MM/yyyy'),
          },
          {
            header: 'Assigned to',
            dataKey: 'assignee',
            widthRatio: 16,
            cell: row => (
              <>
                {row.assignee || 'Unassigned'}

                {!isInactiveClient(row) &&
                  shouldShowAssignButton({
                    client: row,
                    loggedInUser,
                    ability,
                  }) && (
                    <EditButton
                      {...getDataTestId(`${row.id}-edit-assignee-button`)}
                      onClick={() => onAssignClick(row)}
                    >
                      <Icon
                        name="edit-outline"
                        size={20}
                        color={theme => theme.palette.grey[700]}
                      />
                    </EditButton>
                  )}
              </>
            ),
            filter: (
              <Filter
                name="assignees"
                options={reviewers.map(user => ({
                  value: user.id,
                  text: getDisplayField(user),
                }))}
                control={control}
              />
            ),
          },
          {
            header: 'Documents by statuses',
            widthRatio: 30,
            cell: row => (
              <StackBar
                data={(row.documentStatuses || [])
                  .filter(
                    document => document.value !== DocumentStatus.Generated,
                  )
                  .map(({ count, value }) => ({
                    count,
                    color: theme => theme.palette.status[value]?.main,
                    title: StatusText[value],
                  }))}
              />
            ),
          },
        ]}
      />
    </InfiniteTablePage>
  );
}
