import { DocumentAccordionGroupProps } from './DocumentAccordion.interface';
import { DocumentAccordionItem } from './DocumentAccordionItem';
import { groupDocumentsWithoutMerge } from '@/utils';
import { DocumentStatus, RequiredCondition, RequiredDocument } from '@/types';
import { DocumentAccordionPendingItem } from './DocumentAccordionPendingItem';
import { Fragment, useCallback, useMemo } from 'react';

export function DocumentAccordionGroup({
  group,
  documents,
  disableDocumentCache,
  itemProps,
}: DocumentAccordionGroupProps) {
  const isInactive = useMemo(() => {
    if (group.condition === RequiredCondition.And) {
      return false;
    }

    return group.items.some(doc =>
      [DocumentStatus.Approved, DocumentStatus.InReview].includes(
        doc.status as DocumentStatus,
      ),
    );
  }, [group.condition, group.items]);

  const documentsByType = useMemo(
    () => groupDocumentsWithoutMerge(documents),
    [documents],
  );

  const renderDocumentItem = useCallback(
    (doc: RequiredDocument) => {
      const clientDocuments = documentsByType[doc.name] || [];

      if (clientDocuments.length) {
        return (
          <Fragment key={doc.name}>
            {clientDocuments.map(doc => (
              <DocumentAccordionItem
                key={doc.id}
                item={doc}
                disableDocumentCache={disableDocumentCache}
                {...itemProps}
              />
            ))}
          </Fragment>
        );
      }

      if (!isInactive) {
        return (
          <DocumentAccordionPendingItem
            key={doc.name}
            title={doc.displayName}
          />
        );
      }

      return null;
    },
    [disableDocumentCache, documentsByType, isInactive, itemProps],
  );

  return <>{group.items.map(doc => renderDocumentItem(doc))}</>;
}
