export enum SummaryPeriod {
  Today = 'today',
  Month = 'month',
  Year = 'year',
}

export type SummaryStats = {
  inReview: number;
  openedAccounts: number;
  pendingApproval: number;
  pendingCustomer: number;
  rejectedAccounts: number;
  responseTime: number;
  total: number;
};

export type MyTasksStats = {
  dueToday: number;
  pastDue: number;
  pendingApproval: number;
  total: number;
};

export type OpeningAccountStats = {
  opened: number;
  requested: number;
  time: string;
}[];
