import { doNotForwardTheseProps, elevationLevel } from '@/styles';
import {
  Badge,
  Box,
  Chip as MUIChip,
  ChipProps,
  ListItem,
  ListItemIcon,
  LinearProgress as MUILinearProgress,
  Paper,
  Tab,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import { Icon } from '../Icon';
import { OverlayScroller } from '../Layout';

export const LinearProgress = styled(MUILinearProgress, {
  shouldForwardProp: doNotForwardTheseProps('valueColor', 'bgColor'),
})<{
  valueColor?: string;
  bgColor?: string;
}>(({ theme, valueColor, bgColor }) => ({
  height: 8,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: bgColor || theme.palette.secondaryPurple[200],
    borderRadius: 8,
  },
  [`.${linearProgressClasses.bar}`]: {
    backgroundColor: valueColor || theme.palette.secondaryPurple[500],
    borderRadius: 8,
  },
}));

export const ProgressStepsContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  height: 30,
  borderRadius: 24,
  padding: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
}));

export const ProgressStepsBar = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('color'),
})<{
  color?: string;
}>(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: 22,
  padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
  borderRadius: 16,
  backgroundColor: color || theme.palette.green[800],
  minWidth: 'fit-content',
}));

export const FigureCard = styled(Box, {
  shouldForwardProp: doNotForwardTheseProps('bgColor'),
})<{
  bgColor?: string;
}>(({ theme, bgColor, onClick }) => ({
  borderRadius: 8,
  backgroundColor: bgColor || theme.palette.grey[100],
  width: '100%',
  whiteSpace: 'pre-wrap',
  position: 'relative',
  cursor: onClick ? 'pointer' : 'default',
}));

export const WarningIcon = styled(Icon)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(1),
}));

export const ActivityListContainer = styled(OverlayScroller)(({ theme }) => ({
  padding: 0,
  maxHeight: 506,
  [theme.breakpoints.up('xl')]: {
    maxHeight: 400,
  },
}));

export const ActivityItemContainer = styled(ListItem)(({ theme }) => ({
  padding: 0,
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5),
  '.MuiListItemIcon-root': {
    minWidth: 0,
  },
  '.MuiListItemSecondaryAction-root': {
    right: 0,
  },
}));

export const ChartIndicatorContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}));

export const ChartIndicatorDot = styled('div', {
  shouldForwardProp: doNotForwardTheseProps('color'),
})<{ color?: string }>(({ theme, color }) => ({
  backgroundColor: color || theme.palette.text.primary,
  borderRadius: '50%',
  height: 8,
  width: 8,
}));

export const ProgressDot = styled('div', {
  shouldForwardProp: doNotForwardTheseProps('isHighlight'),
})<{ isHighlight?: boolean }>(({ theme, isHighlight }) => ({
  backgroundColor: isHighlight
    ? theme.palette.green[500]
    : theme.palette.grey[300],
  borderRadius: 11,
  height: 8,
  width: 10,
}));

export const IconBadge = styled(Badge, {
  shouldForwardProp: doNotForwardTheseProps('bgColor', 'big'),
})<{ bgColor?: string; big?: boolean }>(
  ({ theme, bgColor, big, invisible }) => ({
    '> .MuiBadge-badge': {
      background: bgColor || theme.palette.primaries[500],
      borderRadius: '50%',
      transform: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 0,
      minWidth: 0,

      ...(invisible
        ? {
            display: 'none',
          }
        : big
          ? {
              display: 'flex',
              height: 24,
              width: 24,
              right: -4,
              bottom: -4,
              '.MuiSvgIcon-root': {
                fill: theme.palette.common.white,
                height: 16,
                width: 16,
              },
            }
          : {
              display: 'flex',
              height: 16,
              width: 16,
              right: -4,
              bottom: 0,
              '.MuiSvgIcon-root': {
                fill: theme.palette.common.white,
                height: 11,
                width: 11,
              },
            }),
    },
  }),
);
IconBadge.defaultProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'right',
  },
};

export const DotBadge = styled(Badge, {
  shouldForwardProp: doNotForwardTheseProps('darkBorder', 'big'),
})<{ darkBorder?: boolean; big?: boolean }>(
  ({ theme, darkBorder, big, invisible }) =>
    invisible
      ? {}
      : {
          '> .MuiBadge-badge': {
            background: darkBorder
              ? `linear-gradient(to right, ${theme.palette.badgeBackground.start}, ${theme.palette.badgeBackground.end})`
              : theme.palette.background.paper,
            borderRadius: '50%',
            transform: 'none',

            ...(big
              ? {
                  right: 2,
                  top: 2,
                  height: 14,
                  width: 14,
                }
              : {
                  right: -2,
                  top: -2,
                  height: 12,
                  width: 12,
                }),

            '::before': {
              borderRadius: '50%',
              content: '""',
              position: 'absolute',
              backgroundColor: theme.palette.secondary.main,
              height: 'calc(100% - 4px)',
              width: 'calc(100% - 4px)',
              right: 2,
              top: 2,
            },
          },
        },
);
DotBadge.defaultProps = {
  variant: 'dot',
};

export const Chip = styled(
  (props: Omit<ChipProps, 'color'>) => <MUIChip {...props} />,
  {
    shouldForwardProp: doNotForwardTheseProps(
      'bgColor',
      'color',
      'borderColor',
    ),
  },
)<{
  color?: string;
  bgColor?: string;
  borderColor?: string;
  size?: 'small' | 'medium';
}>(({ theme, color, bgColor, borderColor, size = 'medium' }) => ({
  backgroundColor: bgColor || theme.palette.grey[200],
  color: color || theme.palette.grey[700],
  border: borderColor ? `1px solid ${borderColor}` : 'none',
  userSelect: 'none',

  ...(size === 'small'
    ? {
        height: 16,
        padding: `${theme.spacing(0.125)} ${theme.spacing(0.625)}`,
      }
    : {
        height: 22,
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
      }),

  '.MuiChip-label': {
    textTransform: 'uppercase',
    padding: 0,
    fontSize: theme.typography.caption2Bold.fontSize,
    fontWeight: theme.typography.caption2Bold.fontWeight,
  },
}));

export const TabChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.primaries[400],
  color: theme.palette.common.white,
  border: 'none',
  height: 16,
  padding: `${theme.spacing(0.125)} ${theme.spacing(0.625)}`,
  minWidth: 17,
}));

export const StyledTab = styled(Tab)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  gap: theme.spacing(1),

  '.MuiTypography-root': {
    color: theme.palette.grey[700],
  },
  '&.Mui-selected': {
    '.MuiTypography-root': {
      color: theme.palette.grey[1000],
      fontWeight: theme.typography.body2Medium.fontWeight,
    },
  },
}));

export const StatusBlock = styled(Box)(() => ({
  fontSize: 0,
}));

export const StackBarContainer = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 79,
  height: 26,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  padding: `0 ${theme.spacing(2)}`,
  width: '100%',
}));
StackBarContainer.defaultProps = {
  elevation: elevationLevel.m,
};

export const StackBarTotal = styled(Typography)(() => ({
  width: 24,
  overflow: 'hidden',
}));
StackBarTotal.defaultProps = {
  variant: 'body2',
};

export const StackBarElement = styled('div', {
  shouldForwardProp: doNotForwardTheseProps('color', 'ratio'),
})<{ color?: string; ratio?: number }>(({ theme, color, ratio = 1 }) => ({
  backgroundColor: color || theme.palette.grey[500],
  height: 8,
  borderRadius: 138,
  flexGrow: ratio,
}));

export const StatusIconContainer = styled(ListItemIcon, {
  shouldForwardProp: doNotForwardTheseProps('bgColor', 'borderColor'),
})<{ bgColor?: string; borderColor?: string }>(
  ({ theme, bgColor, borderColor }) => ({
    backgroundColor: bgColor || theme.palette.grey[200],
    border: borderColor ? `1px solid ${borderColor}` : 'none',
    borderRadius: '50%',
    minWidth: 0,
    marginRight: theme.spacing(2),
    height: 48,
    width: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
);

export const ChartTooltipContent = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[200]}`,
  padding: theme.spacing(2),
}));
ChartTooltipContent.defaultProps = {
  elevation: elevationLevel.m,
};

export const FieldActionSlot = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '100%',
  top: '50%',
  transform: `translate(${theme.spacing(1)}, -50%)`,
}));

export const MetadataViewerContainer = styled(OverlayScroller)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 10,
  padding: theme.spacing(2),
  maxHeight: 250,

  code: {
    whiteSpace: 'pre-wrap',
  },
}));
