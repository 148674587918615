import { FilterBar } from '@/components';
import { useMemo } from 'react';
import { DocumentFilter } from './Documents.interface';
import { DOCUMENT_TYPE_TEXT, StatusText } from '@/constants';
import { DocumentStatus, DocumentType, Option, User } from '@/types';
import { useSelector } from '@/hooks';
import { selectLoggedInUser } from '@/redux';
import { UseFormSetValue } from 'react-hook-form';

type DocumentsFilterBarProps = {
  currentFilters: DocumentFilter;
  onSetFilters: UseFormSetValue<DocumentFilter>;
};

export function DocumentsFilterBar({
  currentFilters,
  onSetFilters,
}: DocumentsFilterBarProps) {
  const loggedInUser = useSelector(selectLoggedInUser);

  const filterOptions = useMemo(() => {
    const result: Option[] = [];

    if (currentFilters.statuses?.length) {
      result.push(
        ...currentFilters.statuses.map(
          status =>
            ({
              value: status,
              text: `Status: ${StatusText[status as DocumentStatus] || status}`,
              group: 'statuses',
            }) as Option,
        ),
      );
    }

    if (currentFilters.documentTypes?.length) {
      result.push(
        ...currentFilters.documentTypes.map(
          type =>
            ({
              value: type,
              text: `Type: ${DOCUMENT_TYPE_TEXT[type as DocumentType] || type}`,
              group: 'documentTypes',
            }) as Option,
        ),
      );
    }

    if (currentFilters.inReviewOnly) {
      result.push({
        value: (loggedInUser as User).id,
        text: `Status: ${StatusText[DocumentStatus.InReview]}`,
        group: 'inReviewOnly',
      });
    }

    if (currentFilters.keyword) {
      result.push({
        value: currentFilters.keyword,
        text: `Keyword: ${currentFilters.keyword}`,
        group: 'keyword',
      });
    }

    return result;
  }, [currentFilters, loggedInUser]);

  const onClearFilter = (filter: Option['value'], group?: Option['group']) => {
    if (!group) return;

    const key = group as keyof DocumentFilter;

    if (Array.isArray(currentFilters[key])) {
      onSetFilters(
        key,
        currentFilters[key].filter(i => i !== filter),
      );
    } else if (typeof currentFilters[key] === 'boolean') {
      onSetFilters(key, false);
    } else if (typeof currentFilters[key] === 'string') {
      onSetFilters(key, '');
    } else {
      onSetFilters(key, undefined);
    }
  };

  const onClearAllFilters = () => {
    onSetFilters('documentTypes', []);
    onSetFilters('statuses', []);
    onSetFilters('keyword', '');
    onSetFilters('inReviewOnly', false);
  };

  return (
    <FilterBar
      appliedFilters={filterOptions}
      onClear={onClearFilter}
      onClearAll={onClearAllFilters}
      containerProps={{
        mt: 1.25,
        mb: 1,
      }}
    />
  );
}
