import { getFeatures, getOccupationOptions } from '@/api';
import { AppConfigurations, Tenant } from '@/types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

export const setConfigs =
  createAction<Partial<AppConfigurations>>('configs/set');

export const setTenant = createAction<Tenant>('configs/set-tenant');

export const fetchFeatures = createAsyncThunk(
  'user-data/fetch-features',
  getFeatures,
);

export const fetchOccupations = createAsyncThunk(
  'configs/fetch-occupations',
  getOccupationOptions,
);
