import {
  Client,
  ClientStatus,
  Document,
  DocumentSection,
  DocumentStatus,
  DocumentType,
  EmploymentStatus,
  FundingSource,
} from '@/types';
import { configureStore, createSlice, Reducer } from '@reduxjs/toolkit';

export function createMockDocument(data?: Partial<Document>): Document {
  return {
    id: String(new Date().getTime()),
    fileName: `${String(new Date().getTime())}.jpg`,
    type: DocumentSection.ProofOfAddress,
    createdAt: '2024-06-24T12:05:12.632894Z',
    status: DocumentStatus.InReview,
    clientId: '123456',
    clientName: 'John Doe',
    ...data,
  };
}

export function createMockClient({
  data,
  documents = [],
}: {
  data?: Partial<Client>;
  documents?: Document[];
} = {}): Client {
  return {
    id: String(new Date().getTime()),
    phoneNumber: '1284123456789',
    email: 'abc@example.com',
    username: 'abcxyz',
    firstName: 'John',
    lastName: 'Doe',
    birthday: '1989-11-20',
    citizenship: 'ARG',
    country: 'USA',
    taxResidence: 'USA',
    status: ClientStatus.InReview,
    employmentStatus: EmploymentStatus.Employed,
    rim: '12345',
    icbResponseCode: 0,
    tin: {},
    // accountTypes: [
    //   {
    //     id: 'd911d321-258d-46c6-8368-37b8446a9530',
    //     name: 'Personal Chequing Account',
    //   },
    // ],
    // pepAnswerStatus: PEPAnswerStatus.NotAnswered,
    termsAndConditions: true,
    addressDetailed: {
      line1: '10600 Highland Springs Avenue',
      city: 'Beaumont',
      state: 'California',
      zip: '92223',
    },
    documents: [
      ...documents,
      createMockDocument({
        vuOperationId: 1234,
        vuOperationGuid: String(new Date().getTime()),
        type: DocumentSection.Selfie,
      }),
      createMockDocument({
        vuOperationId: 1234,
        vuOperationGuid: String(new Date().getTime()),
        type: DocumentSection.Identity,
        docType: DocumentType.Id,
        docNumber: '12345678',
        expiryDate: '2040-10-08',
      }),
    ],
    branchCode: 812,
    branchName: 'The Valley',
    branchId: '12312321',
    fundingSource: FundingSource.Salary,
    monthlyIncome: 120,
    createdAt: '2024-09-26T13:30:43.327487Z',
    requiredDocumentSections: [],
    ...data,
  };
}

export function createMockReducer(initialState: object = {}) {
  const slice = createSlice({
    name: 'mock-reducer',
    initialState: initialState,
    reducers: {},
  });

  return slice.reducer;
}

export function createMockStore(reducer: Record<string, Reducer>) {
  const mockStore = configureStore({
    reducer: reducer,
  });

  return mockStore;
}
