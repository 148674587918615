import { Can, DocumentAccordion, Status, UploadButton } from '@/components';
import { useDispatch } from '@/hooks';
import {
  fetchClientDetails,
  showDocumentReviewDrawer,
  showToast,
} from '@/redux';
import {
  Client,
  Document,
  DocumentSection,
  DocumentStatus,
  DocumentType,
  IconName,
} from '@/types';
import { CircularProgress, Typography } from '@mui/material';
import { UploadBox, UploaderLoadingContainer } from './ClientDetails.styled';
import {
  DOCUMENT_TYPE_TEXT,
  MAX_DOCUMENT_SIZE,
  SUPPORTED_INTERNAL_DOCUMENT_EXTENSIONS,
} from '@/constants';
import { useEffect } from 'react';
import { formatTime, getAPIErrorMessage, getFileExtension } from '@/utils';
import { useBoolean } from 'usehooks-ts';
import { useMutation } from '@tanstack/react-query';
import { addClientDocument } from '@/api';

type AdditionalDocumentAccordionProps = {
  title: string;
  client: Client;
  section: DocumentSection;
  documents?: Document[];
};

export function AdditionalDocumentAccordion({
  title,
  client,
  section,
  documents,
}: AdditionalDocumentAccordionProps) {
  const {
    value: isDraggingOn,
    setTrue: showDraggingOn,
    setFalse: hideDraggingOn,
  } = useBoolean(false);
  const dispatch = useDispatch();

  const {
    mutate: upload,
    isPending,
    isSuccess,
    reset,
    error,
  } = useMutation({
    mutationFn: addClientDocument,
  });

  useEffect(() => {
    if (isSuccess) {
      dispatch(fetchClientDetails(client.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  useEffect(() => {
    if (error) {
      dispatch(
        showToast({
          message: getAPIErrorMessage(error),
          severity: 'error',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const onFileSelect = async (files: FileList | null) => {
    reset();

    if (!files || !files.length) return;

    const file = files[0];
    const ext = getFileExtension(file);

    if (!SUPPORTED_INTERNAL_DOCUMENT_EXTENSIONS.includes(ext)) {
      dispatch(
        showToast({
          message: 'Extension not supported',
          severity: 'error',
        }),
      );
      return;
    }

    if (file.size > MAX_DOCUMENT_SIZE) {
      dispatch(
        showToast({
          message: 'File size exceeded',
          severity: 'error',
        }),
      );
      return;
    }

    upload({
      clientId: client.id,
      file: files[0],
      section: section,
      type: files[0].name as DocumentType,
    });
  };

  const onReviewClick = (document: Document) =>
    dispatch(
      showDocumentReviewDrawer({
        client,
        document,
      }),
    );

  const getDescription = (section: DocumentSection) => {
    return documents?.length && section === DocumentSection.Internal
      ? `${documents?.length} document${documents?.length > 1 ? 's' : ''}`
      : '';
  };

  const getIcon = (section: DocumentSection) => {
    return section === DocumentSection.Internal
      ? { statusIcon: 'upload' as IconName }
      : {};
  };

  return (
    <DocumentAccordion
      title={title}
      itemProps={{
        onReviewClick,
        renderDocumentItemTitle: item => {
          return (
            <>
              <Typography variant="body2Medium" mb={0.75}>
                {DOCUMENT_TYPE_TEXT[item.docType || ''] ||
                  item.fileName ||
                  'Document'}
              </Typography>
              <Status size="small" value={item.status} display="block" />
            </>
          );
        },
        renderDocumentItemTime: item => (
          <>
            <Typography
              variant="caption"
              color={theme => theme.palette.grey[700]}
            >
              Last updated
            </Typography>
            <Typography variant="body2">
              {formatTime(item.updatedAt)}
            </Typography>
          </>
        ),
      }}
      status={
        documents?.length ? DocumentStatus.Approved : DocumentStatus.Pending
      }
      description={getDescription(section)}
      defaultExpanded
      documents={documents}
      {...getIcon(section)}
    >
      <Can do="edit" on="onboarding_application">
        <UploadBox
          isHighlighted={isDraggingOn}
          onDragEnter={showDraggingOn}
          onDragLeave={hideDraggingOn}
          onDrop={hideDraggingOn}
        >
          {isDraggingOn ? (
            <Typography
              variant="captionBold"
              textAlign="center"
              color={theme => theme.palette.primary.main}
            >
              Drop here
            </Typography>
          ) : (
            <Typography
              variant="caption"
              textAlign="center"
              color={theme => theme.palette.grey[700]}
            >
              <Typography
                variant="captionBold"
                component="span"
                color={theme => theme.palette.grey[1000]}
              >
                Select file
              </Typography>{' '}
              to upload or drag it here
              <br />
              Only {SUPPORTED_INTERNAL_DOCUMENT_EXTENSIONS.join(', ')}, 50 MB or
              less
            </Typography>
          )}
          {isPending && (
            <UploaderLoadingContainer>
              <CircularProgress size={20} />
            </UploaderLoadingContainer>
          )}
          <UploadButton
            onChange={onFileSelect}
            accept={SUPPORTED_INTERNAL_DOCUMENT_EXTENSIONS.join(',')}
            disabled={isPending}
          >
            Upload
          </UploadButton>
        </UploadBox>
      </Can>
    </DocumentAccordion>
  );
}
